import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import burnBg from '../../css/images/burn-bg.png'
import burnBlackOrange from '../../css/images/burn-black-orange.png'
import man2 from '../../css/images/man2.jpg'
import man from '../../css/images/man.jpg'
import man3 from '../../css/images/man3.jpg'
import burnWhite from '../../css/images/burn-white.png'
import flame from '../../css/images/flame.png'
import woman from '../../css/images/woman.jpg'
import {UserContext} from '../../context/UserContext'
import leftArrow from  '../../css/images/left-arrow.png'
import axios from 'axios'
import moment from 'moment'

export default function Blocks() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(true)
    const {sportID,langID}= useContext(UserContext)
    const [sports,setSports]=useState([])
    const [countries,setCountries]=useState([])
    const [positions,setPositions]=useState([])
    const [wallOfFame,setWallOfFame]=useState([])
    const [profileSaved,setProfileSaved]=useState(0)
    const [friends,setFriends]=useState([])
    const {userUUID}=useParams()

    const navigate=useNavigate()

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'block/'+userID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
    
          setFriends(res.data.friends)
          setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            //console.log(err)
        }) 
    }


    const blockUser=(bid)=>{
      const obj={
        UserID:userID,
        BlockedID:bid,
        action:0
      }
    
      axios.post(process.env.REACT_APP_API_SERVER+'block',obj,{
        headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }  
      }).then(res=>{
        if (res.data.err==0){
          navigate('/menu')
          //loadData()
        }else{
          alert('An error has occured')
        }
      })
    }



    
    const friendsList=friends.map(item=>{
        const authorImg=`${process.env.REACT_APP_API_SERVER}files/${item.BlockedUUID}/1234`

        return(
            <div className="chat-avatar-item">
            <div className="chat-user-photo" style={{backgroundImage:'url('+authorImg+')'}}></div>
            <div className="chat-header">
                <div className="chat-header-text">{item.FullName}</div>
                <div className="chat-header-time">{moment(item.BDate).format('DD/MM/YYYY')} </div>
                <button onClick={()=>blockUser(item.BlockedUUID)} className="btn btn-success">Unblock</button>

                <hr/>
            </div>      
        </div>
        )
    })


  return (
    <div class="container container-mobile bg-grey">
      <header>
    <div className="upper-section upper-dark">
    <div className="d-flex align-items-center">
      <div className="upper-arrow">
        <button onClick={()=>navigate('/menu')}><img src={leftArrow}/></button>
      </div>
      <div className="upper-texts">
        <div className="text1-w"></div>
        <div className="text2-g">blocked users</div>
      </div>
    </div>

  </div>    
</header>

<section className="section-margin">


    {!isLoading &&
    <>
      {friendsList}

    </>
    }



      {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
     }

</section>

</div>


  )
}
