import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import {UserContext} from '../../../context/UserContext'
import '../../../css/style.css'
import axios from 'axios'
import cube2 from '../../../css/images/cube2.png'
import foutline from '../../../css/images/f-outline.png'
import thunder7w from '../../../css/images/thunder7-w.png'
import medal3w from '../../../css//images/medal3-w.png'

import foutline2 from '../../../css/images/f-outline2.png'
import thunder5 from '../../../css/images/thunder5.png'
import achievement from '../../../css/images/achievement.png'

import octagon from '../../../css/images/octagon.png'

import flame from '../../../css/images/flame.png'

import points3c from '../../../css/images/points3c.png'
import fire3white from '../../../css/images/fire3-white.png'

import points2c from '../../../css/images/points2c.png'
import thunder5w from '../../../css/images/thunder5-w.png'

import points1c from '../../../css/images/point1c.png'
import medalw from '../../../css/images/medal-w.png'

import leftarrow from '../../../css/images/left-arrow.png'



export default function Content() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
    const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
    const [articles,setArticles]=useState([])
    const [articlesFlt,setArticlesFlt]=useState([])
    const [isLoading,setIsLoading]=useState(true)
    const [isLoadingReactions,setIsLoadingReactions]=useState(true)
    const [isReacting,setIsReacting]=useState(false)
    const [challenge,setChallenge]=useState({})
    const [reactions,setReactions]=useState([])
    const [reportDetail,setReportDetail]=useState('')
    const [reportSent,setReportSent]=useState(false)
    const [hasResults,setHasResults]=useState(false)
    const [success,setSuccess]=useState(false)
    const [marginTop,setMarginTop]=useState(0)
    const {itemToView,setItemToView}= useContext(UserContext)
    const [totalRecations3,setTotalReactions3]=useState(0)
    const [totalRecations2,setTotalReactions2]=useState(0)
    const [totalRecations1,setTotalReactions1]=useState(0)
    const vidEl=useRef()
    const navigate=useNavigate()
  
    useEffect(()=>{
        setReactions(itemToView.Reactions)
        //console.log(itemToView)
        // const videoElement = document.getElementById('videoPlayer');
        // let isFullscreen = false;
      
        // videoElement.addEventListener('click', () => {
        //   if (!isFullscreen) {
        //     if (videoElement.requestFullscreen) {
        //       videoElement.requestFullscreen();
        //     } else if (videoElement.mozRequestFullScreen) { // Για Firefox//
        //       videoElement.mozRequestFullScreen();
        //     } else if (videoElement.webkitRequestFullscreen) { // Για Chrome, Safari και Opera//
        //       videoElement.webkitRequestFullscreen();
        //     } else if (videoElement.msRequestFullscreen) { // Για Edge//
        //       videoElement.msRequestFullscreen();
        //     }
        //   } else {
        //     if (document.exitFullscreen) {
        //       document.exitFullscreen();
        //     } else if (document.webkitExitFullscreen) { // Για Chrome, Safari και Opera//
        //       document.webkitExitFullscreen();
        //     } else if (document.mozCancelFullScreen) { // Για Firefox//
        //       document.mozCancelFullScreen();
        //     } else if (document.msExitFullscreen) { // Για Edge//
        //       document.msExitFullscreen();
        //     }
        //   }
        //   isFullscreen = !isFullscreen;
        // });
        calculateTotalReactions()

        if (document.getElementById("chalVideoWrapper").offsetHeight<document.getElementById("chalVideoWrapper").offsetWidth){
          setMarginTop(document.getElementById("chalVideoWrapper").offsetHeight*0.8)
        }
    

    },[])

    const calculateTotalReactions=()=>{
      var tr=0
      if (itemToView.Reactions.filter(xitem=>xitem.RankType==3).length>0){
        itemToView.Reactions.filter(xitem=>xitem.RankType==3).forEach(xitem=>{
              tr=tr+parseInt(xitem.v)
        })
        setTotalReactions3(tr)  
      }
      var tr=0
      if (itemToView.Reactions.filter(xitem=>xitem.RankType==2).length>0){
          itemToView.Reactions.filter(xitem=>xitem.RankType==2).forEach(xitem=>{
              tr=tr+parseInt(xitem.v)
          })
          setTotalReactions2(tr) 
      }
      var tr=0
      if (itemToView.Reactions.filter(xitem=>xitem.RankType==1).length>0){
        itemToView.Reactions.filter(xitem=>xitem.RankType==1).forEach(xitem=>{
              tr=tr+parseInt(xitem.v)
          })
          
          setTotalReactions1(tr) 
      }

  }


  const toggleActive=(r)=>{
    var fire = document.getElementById("f-animation");
    var thunder = document.getElementById("thunder-animation");
    var medal = document.getElementById("achievement-animation");

    var points3 = document.getElementById("fire-full");
    var points2 = document.getElementById("thunder-full");
    var point1 = document.getElementById("award-full");

    if (r==3){
      fire.src = `${foutline2}`//"images/f-outline2.png";
      thunder.src = `${thunder5w}`//"images/thunder5-w.png";
      medal.src =`${medal3w}`// "images/medal-w.png";
      fire.classList.add("zoom-anim"); 
      points3.classList.remove("hide");
      points3.classList.add("zoom-points");
      setTimeout(function(){         
          fire.classList.remove("zoom-anim"); 
          points3.classList.add("hide")
      }, 1000);
    }

    if (r==2){
      thunder.src = `${thunder5}`//"images/thunder5.png";
      fire.src = `${fire3white}`//"images/fire3-white.png";
      medal.src = `${medalw}`//"images/medal-w.png";
      thunder.classList.add("zoom-anim");
      points2.classList.remove("hide");
      points2.classList.add("zoom-points");        
      setTimeout(function(){ 
          thunder.classList.remove("zoom-anim"); 
          points2.classList.add("hide")
      }, 1000);        
    }


    if (r==1){
      medal.src = `${achievement}`//"images/achievement.png";
      fire.src = `${fire3white}`//"images/fire3-white.png";
      thunder.src = `${thunder5w}`//"images/thunder5-w.png";
      medal.classList.add("zoom-anim"); 
      point1.classList.remove("hide"); 
      point1.classList.add("zoom-points");  
      setTimeout(function(){ 
          medal.classList.remove("zoom-anim"); 
          point1.classList.add("hide")
      }, 1000);        
    }

    
    const hadAReaction=reactions.filter(xitem=>xitem.ReactUserUUID==userID).length==1
    var xreactions=reactions//.filter(xitem=>xitem.ReactUserUUID!=userID)
    

    if (hadAReaction){
        //console.log('Has a reaction')
        //console.log(reactions.filter(xitem=>xitem.ReactUserUUID==userID && xitem.RankType==r))
        if (reactions.filter(xitem=>xitem.ReactUserUUID==userID && xitem.RankType==r).length==1){
           // console.log('Remove reaction')
            xreactions=[...xreactions.filter(xitem=>xitem.ReactUserUUID!=userID)]
            
            setReactions(xreactions)
        }else{
            //console.log('Edit reaction')
            xreactions.forEach(xr=>{
                if (xr.ReactUserUUID==userID){
                    xr.RankType=r
                }
            })
            setReactions([...xreactions])                    
        }
        

        
    }else{   
       // console.log('New reaction')         
        const obj={
            ChUserID:0,
            ChUserUUID:'',
            ReactUserUUID:userID,
            ReactUserID:0,
            err:0,
            ChallengeID:itemToView.ItemID,
            v:1,
            RankType:r
        }

        xreactions.push(obj)
        setReactions([...xreactions])
    }
    //console.log(xreactions)
    calculateTotalReactionsState(xreactions)
    sendReaction(r)

}

const sendReaction=(r)=>{
  const obj={
      ChallengeID:itemToView.ItemUUID,
      ChUserID:itemToView.UsrUUID,
      ReactUserID:userID,
      Rank:r,
      LangID:langID,
      ReactFullName:firstName+" "+lastName
  }
  
  var url=''
  if (itemToView.Type==3){
      url=process.env.REACT_APP_API_SERVER+'challengereactions'
  }
  if (itemToView.Type==2){
      url=process.env.REACT_APP_API_SERVER+'postreactions'
  }
  //console.log(url)
  axios.post(url,obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{


     // console.log(res.data)
      
    }).catch(err=>{
      console.log(err)

    })

   // console.log(reactions)
}

const calculateTotalReactionsState=(reactionsArr)=>{
  //console.log(reactionsArr)
  var tr=0
  if (reactionsArr.filter(xitem=>xitem.RankType==3).length>0){
      reactionsArr.filter(xitem=>xitem.RankType==3).forEach(xitem=>{
          tr=tr+parseInt(xitem.v)
      })
      setTotalReactions3(tr)  
  }else{
    setTotalReactions3(0) 
  }

  var tr=0
  if (reactionsArr.filter(xitem=>xitem.RankType==2).length>0){
      reactionsArr.filter(xitem=>xitem.RankType==2).forEach(xitem=>{
          tr=tr+parseInt(xitem.v)
      })
      setTotalReactions2(tr)  
  }else{
    setTotalReactions2(0)  
  }

  var tr=0
  if (reactionsArr.filter(xitem=>xitem.RankType==1).length>0){
      reactionsArr.filter(xitem=>xitem.RankType==1).forEach(xitem=>{
          tr=tr+parseInt(xitem.v)
      })
      setTotalReactions1(tr)  
  }else{
    setTotalReactions1(0) 
  }

}

  return (
    <>
      <section>

        <div className="chal-video-wrapper" style={{marginTop}} id="chalVideoWrapper">


          {/* <video id="videoPlayer" controls  width="100%" height="100%" allowfullscreen playsinline autoplay loop muted>
            <source src="images/video5.mp4" type="video/mp4"/>
            Your browser does not support the video tag.
          </video> */}

            {itemToView.VideoID.length>0 &&
            
          <video 
            ref={vidEl}
            id="videoPlayer" 
            allowfullscreen 
            preload="false" 
            playsInline 
            type="video/mp4"  
            controls 
            width="100%" 
            height="100%" 
            poster={process.env.REACT_APP_VIDEOSERVER_API+''+itemToView.VideoID+'.jpg'}
            //onLoadedMetadata={handleLoadedMetadata}
          >
            <source src={`${process.env.REACT_APP_VIDEO_BASE_URL}${itemToView.VideoID}.smil/playlist.m3u8#t0.01`} type="video/mp4"/>
            </video>
          }

            {itemToView.VideoID.length==0 &&

              <video 
              ref={vidEl}
              id="videoPlayer" 
              allowfullscreen 
              preload="false" 
              playsInline 
              type="video/mp4"  

              width="100%" 
              height="100%" 
              poster={process.env.REACT_APP_API_SERVER+'postfiles/'+itemToView.ItemUUID+'/123'}
              //onLoadedMetadata={handleLoadedMetadata}
            >
              {/* <source src={`${process.env.REACT_APP_VIDEO_BASE_URL}${itemToView.VideoID}.smil/playlist.m3u8#t0.01`} type="video/mp4"/> */}
              </video>
            }
          <div className="chal-left">
            <div className="chal-name-info">
              <div className="text-orange">{itemToView.UsrName}</div>
              <div className="text2-w">
                <b>{itemToView.Title}</b> {itemToView.Descr}
              </div>
            </div>
          </div>

    

          <div className="chal-right">
            <div className="chal-press-points-full">
              <div onClick={()=>navigate('/profile/'+itemToView.UsrUUID)} className="upper-photo2" style={{backgroundImage:'url('+process.env.REACT_APP_API_SERVER+'files/'+itemToView.UsrUUID+'/123)'}}></div>
              <div className="d-flex">
                <div className="points-animation">
                  <div id="fire-full" className="full-animation2 hide">
                    <img src={points3c} alt="fire-animated"/>
                  </div>
                </div>
                <div>
                  <button onClick={()=>toggleActive(3)} className="challenge-press-button" id="challenge-button3">
                  

                    <img src={reactions.filter(xitem=>xitem.RankType==3 && xitem.ReactUserUUID==userID && xitem.ChallengeID==itemToView.ItemID).length>0 ? foutline2 :fire3white }  id="f-animation"  alt="fire icon" className=""/></button>
                  <div className="text-center">{totalRecations3}</div>
                </div>
              </div>
              <div className="d-flex">
                <div className="points-animation">
                  <div id="thunder-full" className="full-animation2 hide">
                    <img src={points2c} alt="thunder-animated"/>
                  </div>
                </div>
                <div>
                
                
                  <button onClick={()=>toggleActive(2)} className="challenge-press-button" id="challenge-button2">
                    <img src={reactions.filter(xitem=>xitem.RankType==2 && xitem.ReactUserUUID==userID && xitem.ChallengeID==itemToView.ItemID).length>0 ? thunder5 : thunder5w } id="thunder-animation"   alt="thunder icon"/></button>
                  <div className="text-center">{totalRecations2}</div>
                </div>
              </div>
              <div className="d-flex">
                <div className="points-animation">
                  <div id="award-full" className="full-animation2 hide">
                    <img src={points1c} alt="medal-animated" id="award"/>
                  </div>
                </div>
                <div>
                
                
                  <button onClick={()=>toggleActive(1)} className="challenge-press-button" id="challenge-button1">
                    <img src={reactions.filter(xitem=>xitem.RankType==1 && xitem.ReactUserUUID==userID && xitem.ChallengeID==itemToView.ItemID).length>0 ? achievement : medalw } id="achievement-animation"  alt="achievement icon"/></button>
                  <div className="text-center">{totalRecations1}</div>
                </div>
              </div>
              <div className="upper-arrow" onClick={()=>navigate(itemToView.Page)}>
                <button ><img src={leftarrow} alt="back-arrow"/></button>
              </div>
           </div>
          </div>  

        </div>
      </section>    
    
    </>
  )
}
