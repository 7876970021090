import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import leftArrow from  '../../css/images/left-arrow.png'
import {UserContext} from '../../context/UserContext'
import playButton from '../../css/images/play-button.png'

import axios from 'axios'

export default function ChallengeExplainerVideo() {
    const fileInputRef = useRef(null)
    const vidEl=useRef()
  
    const [isLoading,setIsLoading]=useState(false)
    const [challenge,setChallenge]=useState({})
    const {id}=useParams()
    const {langid}=useParams()
    const navigate=useNavigate()

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'challenge/'+id+'/'+langid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
           console.log(res.data) 
           setChallenge(res.data[0])
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
        })          
    }

  return (
    <>
    
    <div className="chal-video-wrapper">


      {isLoading &&
          <div className="alert alert-warning" role="alert">
          Loading, please wait...
          </div>          
        }
              
    {!isLoading &&
      // <section className="section-margin">
      //     <div className="rounded-video">
          
      //       <video controls width="320" poster={process.env.REACT_APP_VIDEOSERVER_API+''+challenge.VideoID+'.jpg'}>
      //       <source src={`https://5f06d0924ebcd.streamlock.net:444/mlg/${challenge.VideoID}.smil/playlist.m3u8`} preload="metadata" type="video/mp4"/>
      //           Your browser does not support the video tag.
      //       </video>
      //     </div>     
      // </section>

      <section>
        <div className="chal-video-wrapper"  id="chalVideoWrapper">


            <video controls width="320" poster={process.env.REACT_APP_VIDEOSERVER_API+''+challenge.VideoID+'.jpg'}>
                <source src={`https://5f06d0924ebcd.streamlock.net:444/mlg/${challenge.VideoID}.smil/playlist.m3u8`} preload="metadata" type="video/mp4"/>
                 Your browser does not support the video tag.
             </video>



          <div class="chal-right">
            <div class="chal-press-points-full">
              
              <div class="upper-arrow">
                <button><img src={leftArrow} onClick={()=>navigate('/challengepost/'+id+'/'+langid)}  alt="back-arrow"/></button>
              </div>
           </div>
          </div>      
        </div>
      </section>

    }




    </div>    
    
    </>
  )
}
