import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import leftArrow from  '../../css/images/left-arrow.png'
import {UserContext} from '../../context/UserContext'


import Footer from '../Components/Footer'
import axios from 'axios'

export default function MenuProfilePhoto() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const [file,setFile]=useState(null)
    const [fileType,setFileType]=useState('')
    const [image, setImage] = useState(null);
    const [uploadPercentage, setUploadPercentage]=useState(0)
    const [isUploading, setIsUpLoading]=useState(false)
    const [pickedImage,setPickedImage]=useState(false)
    const [tempPhoto,setTempPhoto]=useState(usrPhoto)
    const navigate=useNavigate()
    const fileInputRef=useRef()

    useEffect(()=>{
        loadImage()
    },[])

    const uploadFile=async (xfile)=>{
        setIsUpLoading(true)
        setPickedImage(false)
        const formData=new FormData()
        const imgNameArr=xfile.name.split('.')
        const ImgExtension=imgNameArr[imgNameArr.length-1]

    
        formData.append('file',xfile)

        formData.append('id',userID)
        formData.append('imgExtension',ImgExtension)

    
        axios.post(process.env.REACT_APP_API_SERVER+'userprofile/uploadusrphoto',formData,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("token"),
                'Content-Type':'multipart/form-data'
              },
              onUploadProgress: ProgressEvent=>{
                  setUploadPercentage(parseInt(Math.round(ProgressEvent.loaded*100/ProgressEvent.total)))
                 
              }            
          })
          .then(res=>{
    
              if (res.data.err==0){
               //setVideo(res.data.fileID)
               setUploadPercentage(0)
               setIsUpLoading(false)
                loadImage()
               //loadUserDetails()
              }else{
                console.log(res.data.msg)
              }
    
          }).catch(err=>{
            //console.log(err)
            alert("An error has occured, please try again")
          }) 
      }
    
    const selectMedia=()=>{
        fileInputRef.current.click();
    }

    const handleFileChange=(e)=>{
      try{
          if (e.target.files[0].type.includes('image')){
            setFile(e.target.files[0])
            setFileType('p')
            const reader = new FileReader();

            reader.onloadend = () => {
              setTempPhoto(reader.result);
              setPickedImage(true)
            };
        
            if (e.target.files[0]) {
              reader.readAsDataURL(e.target.files[0]);
            }
          }
            // if (e.target.files[0].type.includes('image')){
            //     setFileType('p')
            //     uploadFile(e.target.files[0])
            // }
        }catch(err){

        }  
    }

    const loadImage=()=>{
        const d=Date.now()
        //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
        setUsrPhoto(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
    }

    const cancelUpload=()=>{
      try{
      fileInputRef.current.value=null;
      setIsUpLoading(false)
      setUploadPercentage(0)
      setTempPhoto(usrPhoto)
      setPickedImage(false)
      }catch(err){}
  }
  return (
    <>
    <header>
    <div className="upper-section upper-dark">
      <div className="d-flex align-items-center">
          {/* <div className="upper-arrow">
              <button onClick={()=>navigate('/menu')}><img src={leftArrow}/></button>
            </div> */}
        <div className="upper-texts">
          <div className="text1-w">profile photo</div>
          <div className="text2-g2">{firstName} {lastName}</div>
        </div>
      </div>
      <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')'}}></div>
    </div>    
</header>

<section className="pb-4 section-margin">
<div className="container container-mobile">

            <br/><br/>
    <p align="center">
        <img src={tempPhoto} width="70%"/>
    </p>

    <input 
        type="file" 
        ref={fileInputRef} 
        onChange={handleFileChange} 
        style={{ display: 'none' }} 
    />  

    <p align="center">
    {!isUploading && (!pickedImage) &&
    <>
    <button type="button" class="btn btn-dark" onClick={selectMedia}>Select Photo</button>
    <br/><br/>
    We do not support taking live photos or videos. <br/>Please choose from your gallery
    </>
    }

    {pickedImage &&
    <>
      <button type="button" class="btn btn-success" onClick={()=>uploadFile(file)}>Upload</button>
      <br/><br/>
      <button type="button" onClick={()=>cancelUpload()} class="btn btn-secondary">Cancel</button>
    </>
    
    }

    {isUploading && 
      <>
      <div className="alert alert-secondary" role="alert">
          Uploading {uploadPercentage}%
      </div>  
      <br/><br/>
      <button type="button" onClick={()=>cancelUpload()} class="btn btn-secondary">Cancel</button>
      </>
    }


    </p>
    <br/><br/>

</div>  


</section>
</>
  )
}
