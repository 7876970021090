import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../../css/style.css'

import {UserContext} from '../../../context/UserContext'
// import Footer from '../../Components/Footer'
import axios , {Cance}from 'axios'
import moment from 'moment'
import leftarrow from '../../../css/images/left-arrow.png'
import addImage from '../../../css/images/add-image.png'


export default function HomePagePost2() {

  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userID,setUserID }= useContext(UserContext)
  const {usrPhoto,setUsrPhoto}= useContext(UserContext)
  const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
  const {sportID,setSportID,langID,setLangID,newVideo,setNewVideo}= useContext(UserContext)
  const [articles,setArticles]=useState([])
  const [articlesFlt,setArticlesFlt]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [hasMedia,sethasMedia]=useState(false)
  const [file,setFile]=useState(null)
  const [fileType,setFileType]=useState('')
  const [fileSource, setFileSource] = useState(null)
  const [postText,setPostText]=useState('')
  const [postVideo,setPostVideo]=useState('')
  const [postPhoto,setPostPhoto]=useState('')
  const [upLoading, setUpLoading]=useState(false)
  const [uploadPercentage, setUploadPercentage]=useState(0)
  const [videoURI,setVideoURI]=useState(null)
  const [createStory,setCreateStory]=useState(false)
  const [isShortVideo,setIsShortVideo]=useState(false)
  const [date,setDate]=useState('')
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const navigate=useNavigate()
  const fileInputRef = useRef(null)
  const vidEl=useRef()



    useEffect(()=>{
      let yourDate = new Date()
      const toDay=yourDate.toISOString().split('T')[0]
      setDate(moment(toDay).format('DD/MM/YYYY') )

              // Βρίσκουμε το section και το input
              const section = document.getElementById('challenge-section');
              const fileInput = document.getElementById('file-input');

              // New code for textarea auto-resize
              const textarea = document.getElementById('floatingTextarea');
      
              function adjustTextareaHeight() {
                textarea.style.height = 'auto'; // Reset the height
                textarea.style.height = textarea.scrollHeight + 'px'; // Set it to the scroll height
              }
      
              textarea.addEventListener('input', adjustTextareaHeight);
      
              // Adjust height on load if there is content in the textarea
              adjustTextareaHeight();

    },[])

    const post=()=>{
      const formData=new FormData()
      //formData.append('file',file)
      formData.append('token',process.env.REACT_APP_API_TOKEN)
      formData.append('app','OTH')
      formData.append('postText',postText)
      formData.append('UserID',userID)
      const imgNameArr=file.name.split('.')
      const ImgExtension=imgNameArr[1]
      formData.append('imgExtension',ImgExtension)
      formData.append('fname',file.name)
      
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional, for smooth scrolling
      }); //

      if (fileType=='v'){
          formData.append('file',file)
           uploadVideo(formData)
      }

      if (fileType=='p'){
          formData.append('file',file)
          uploadPhoto(formData)
      }
  }



  const savePost=(pv,pp)=>{
      const toDate = new Date()
      const DateCreated = toDate.toISOString().split('T')[0]

      const postObj={
          DateCreated,
          Descr:postText,
          LangID:langID,
          Photo:pp,
          PostedBy:userID,
          SportID:sportID,
          Title:'',
          VideoID:pv,
          FirstName:firstName,
          LastName:lastName,
          IsStory:createStory ? 1 : 0
      }

      axios.post(process.env.REACT_APP_API_SERVER+'userwallarticles/post',postObj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }          
          })
        .then(res=>{

          
          if (res.data.err==0){
              setUpLoading(false)
              fileInputRef.current.value=null
              
              setUploadPercentage(0)
              if (pv.length>0){
                  setNewVideo(pv)
              }
              navigate('/home')
          }else{
              alert('An Error has occured! Please Try Again')
          }
          
        }).catch(err=>{
          //   setIsLoading(false)
          //   console.log(err)
        })  

  }

  const uploadPhoto=(formData)=>{
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);
      try{
          setUpLoading(true)
          setUploadPercentage(0)
            axios.post(process.env.REACT_APP_API_SERVER+'userwallarticles/uploadpostphoto',formData,{
              cancelToken: source.token,
              headers: {
                  'Content-Type':'multipart/form-data',
                  'Authorization': 'Bearer '+sessionStorage.getItem("token")
                  },
                  onUploadProgress: ProgressEvent=>{
                      setUploadPercentage(parseInt(Math.round(ProgressEvent.loaded*100/ProgressEvent.total)))
                  }            
              })
            .then(res=>{
              if (res.data.err==0){
                  setPostPhoto(res.data.fileID)
                  savePost('',res.data.fileID)

              }else{
                  alert('An Error has occured! Please Try Again')
              }
              
            }).catch(error=>{
              if (axios.isCancel(error)) {
                //alert('By User')
              }else{
                alert(error)
              }

            })  
      }catch(err){
          alert('An Error has occured! Please Try Again')
      }

  }



  const uploadVideo=(formData)=>{
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

      try{
          const url=process.env.REACT_APP_VIDEOSERVER_API+'upload'
          setUpLoading(true)
          setUploadPercentage(0)
          axios.post(url,formData,{
              cancelToken: source.token,
              headers: {
                  'Content-Type':'multipart/form-data',
                  'Authorization': 'Bearer '+sessionStorage.getItem("token")
                  },
                  onUploadProgress: ProgressEvent=>{
                      setUploadPercentage(parseInt(Math.round(ProgressEvent.loaded*100/ProgressEvent.total)))
                  }            
          }).then(res=>{

              if (res.data.err==0){
                  setPostVideo(res.data.fileID)
                  savePost(res.data.fileID,'')

              }else{
                  alert('An Error has occured! Please Try Again')
              }

          }).catch(error=>{
            if (axios.isCancel(error)) {
              //alert('By User')
            }else{
              alert(error)
            }
              
          })



          
     }catch(err){
         alert('An Error has occured! Please Try Again')
     }    
  }    
  
    

  const selectMedia=()=>{
    if (!upLoading){
      fileInputRef.current.click();
    }
      

  }

  const handleFileChange=(e)=>{
    setIsShortVideo(false)
      try{
          sethasMedia(true)
          setFile(e.target.files[0])
          setCreateStory(false)
          var reader = new FileReader();
          reader.onload = function() {
              setVideoURI(reader.result)
          };
          reader.readAsDataURL(e.target.files[0]);

          if (e.target.files[0].type.includes('video')){
              setFileType('v')
              var URL = window.URL || window.webkitURL 
              setFileSource(URL.createObjectURL(e.target.files[0]))

              ///alert(e.target.files[0].name)

          }

          if (e.target.files[0].type.includes('image')){
              setFileType('p')
              var URL = window.URL || window.webkitURL 
              setFileSource(URL.createObjectURL(e.target.files[0]))
          }        

          // console.log(e.target.files[0])
      }catch(err){
          
      }
      
  }

  const reSelectMedia=()=>{
    fileInputRef.current.value=null;
    sethasMedia(false)
    setFile(null)  
    setFileType('')   
    setCreateStory(false)
    fileInputRef.current.click();   
}


const videoIsStory=()=>{
  setCreateStory(!createStory)
  //alert(!createStory)
}


const cancelUpload=()=>{
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Upload cancelled by user.');
    setCancelTokenSource(null);
  }
  //setPostText('')
  setUpLoading(false)
  setUploadPercentage(0)
  reSelectMedia()
}

const handleLoadedMetadata = () => {
  const video = vidEl.current;
  if (!video) return;
  if (video.duration<6.1){
      setIsShortVideo(true)
      reSelectMedia()
  }else{
      setIsShortVideo(false)
  }
  
};

  return (

    <>
      <header>
          <div className="challenge-add-upper-section upper-dark">
            <div class="d-flex align-items-center">
              <div class="upper-arrow">
                <button onClick={()=>navigate('/home')}><img src={leftarrow}/></button>
              </div>
              <div className="text1-w">Create Post</div>
            </div>
          </div>    
      </header>    


      <section>
        <div className="home-avatar-item pt-2">
          <div className="home2-user-photo" style={{backgroundImage:'url('+usrPhoto+')'}}></div>
          <div className="home-header">
              <div className="home-header-text">{firstName} {lastName}</div>
              <div className="home-header-time d-flex">
                {date}
                {/* <i className="bi bi-globe-americas ps-1" style={{color:'black'}}></i>
                <img className="ps-1" src="images/cube3.png" alt="cube icon" style={{height:'14px'}}/>
                <img className="ps-1" src="images/cube2.png" alt="cube icon orange" style={{height:'14px'}}/>               */}
              </div>     
          </div> 
        </div>
          <div className="home2-user-line"></div> 

          <div className="add-post-text">
            <div className="form-floating mb-3">
            
              <textarea 
                disabled={upLoading}
                onChange={(e)=>setPostText(e.target.value)} 
                className="form-control2" 
                id="floatingTextarea" 
                placeholder="write a post" 
                required
              >
              {postText}  
              </textarea>
                   
            </div>
          </div>

          {isShortVideo &&
            
            <div className="alert alert-danger" role="alert">
                Can not upload. Video duration must be greater than 6 sec.
            </div>

          } 
          {upLoading &&
              <div className="alert alert-success" role="alert">
              <p align="center">
              Uploading {uploadPercentage}%
              </p>
              </div>                                 
          } 
          <div className="add-post-media">
            {fileType=='v' &&
          <video 
            ref={vidEl}
            id="videoPlayer" 
            controls  
            width="100%" 
            height="100%" 
            allowFullscreen 
            playsInline autoPlay loop muted 
            onLoadedMetadata={handleLoadedMetadata}
          >
            <source src={fileSource} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
          }
          {fileType=='p' &&
          <img src={fileSource} alt="photo" /> 
          }

          {hasMedia && (!upLoading) &&
            <div className="challenge-add-post-wrapper">
              {/* <button onClick={reSelectMedia} className="paperclip"><i className="bi bi-pencil-fill"></i></button> */}
              {createStory &&
              <button onClick={()=>videoIsStory()} className="btn pin-blue fw-light">UN PIN IT</button>
              }
              {(!createStory) && fileType=='v' &&
              <button onClick={()=>videoIsStory()} className="btn pin-white fw-light">PIN IT</button>
              }
              <button onClick={post} className="btn pin-orange  fw-light">POST {createStory ? 'STORY' : ''}</button>
            </div>
          }
        </div>
         

      </section>    
      

      <section className="challenge-add-choose" id="challenge-section" onClick={selectMedia}>
      <input 
              type="file" 
              ref={fileInputRef} 
              onChange={handleFileChange} 
              accept="image/*,video/*" 
              style={{ display: 'none' }} 
      />         
      {!upLoading &&
        <>

          <img src={addImage} alt="add file icon"/>
          <div class="fs-6">Choose Photography or Video</div>
        </>
      }
      {upLoading &&
      <>
        <br/><br/>
        <button className="post-cancel" onClick={cancelUpload}>Cancel Upload</button>  
        <br/>  
        </>  
      }
      </section>

    </>
  )
}
