import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import Logo from '../../css/images/logo-high3.png'
// //import bgImage from '../../css/images/Signup3.jpg'
// import bgImage from '../../css/images/MainBg.png'
import {UserContext} from '../../context/UserContext'
import Footer from '../Components/Footer'
import axios from 'axios'
import moment from 'moment'
import otthLogo from '../../css/images/logo-b-orange.jpg'
import flame from '../../css/images/flame.png'
import thunder from '../../css/images/thunder-black.png'
import achievement from '../../css/images/achievement.png'
import ReactPlayer from 'react-player'
import PullToRefresh from 'react-simple-pull-to-refresh'
import loading from '../../css/images/loading.gif'
import loadingb from '../../css/images/loadingb.gif'


import HomePost from './HomePost'

export default function Home() {
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userID,setUserID }= useContext(UserContext)
  const {usrPhoto,setUsrPhoto}= useContext(UserContext)
  const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
  const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
  const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
  const [articles,setArticles]=useState([])
  const [reactions,setReactions]=useState([])
  const [postreactions,setPostReactions]=useState([])
  const [articlesFlt,setArticlesFlt]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [scrollY, setScrollY] = useState(0);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [image, setImage] = useState(null);
  const [stories,setStories]=useState([])
  const {usersWhoBlockedMe,setUsersWhoBlockedMe}=useContext(UserContext)
  const {loadUsersWhoBlockedMe}=useContext(UserContext)
  const {newChallenge,setNewChallenge}=useContext(UserContext)
  const [friends,setFriends]=useState([])
  const [searchText,setSearchText]=useState('')
  const { loadUnreadNotifications}=useContext(UserContext)
  const navigate=useNavigate()
  const vidEl=useRef()
  const s=useRef()

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4
    }
  };

  useEffect(()=>{
    loadData()





    window.addEventListener('scroll', handleScroll);
    window.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
     window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('fullscreenchange', handleFullScreenChange);
    };

  },[])



  const loadMyFriends=async ()=>{
    return new Promise((resolve,reject)=>{
      axios.get(process.env.REACT_APP_API_SERVER+'friends/userfriendsidonly/'+userID,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{
  
        setFriends(res.data.friends)
        resolve(res.data.friends)
      })
    })
    
  }

  //-------------------FULL SCREEN CHANGE-------------------------------------------

  const handleFullScreenChange=()=>{
    window.scrollTo({
      top: sessionStorage.getItem('prevScrollY'),//prevScrollY
    });  


    if (document.fullscreenElement){
      window.scrollTo({
        top: sessionStorage.getItem('prevScrollY')+1,//prevScrollY
      });  
    }else{
      window.scrollTo({
        top: sessionStorage.getItem('prevScrollY')+1,//prevScrollY
      });  
    }
  }

  //-------------------END FULL SCREEN CHANGE-------------------------------------------

//-----------------------SCROLL FUNCTIONS---------------------------------------------
const handleScroll=()=>{
  sessionStorage.setItem('prevScrollY',sessionStorage.getItem('scrollY'))
  sessionStorage.setItem('scrollY',window.scrollY)

  if (isVideoInView()) {
    //setPrevScrollY(window.scrollY)
   // console.log('At least one video element is in view.');
    // Do something when a video element is in view
  } else {
    //console.log('No video element is in view.');
    // Do something when no video element is in view
  }    
}


function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function isVideoInView() {
  var videos = document.getElementsByTagName('video');
  for (var i = 0; i < videos.length; i++) {
    if (isElementInViewport(videos[i])) {
      

      for (var k = 0; k < videos.length; k++) {
        videos[k].pause();
      }

      return true;
    }
  }
  return false;
}
//-----------------------END SCROLL FUNCTIONS---------------------------------------------

  const loadData=()=>{
    
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'wallarticles/'+sportID+'/'+langID+'/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
     // console.log(res.data)
      let yourDate = new Date()
      const toDay=yourDate.toISOString().split('T')[0]
      loadMyFriends().then(myFriends=>{
        var articlesResults=[]
        res.data.posts.filter(post=>post.IsStory==0).forEach(item=>{
          if (item.Type==1){
            articlesResults.push(item)
          }else{
              if (item.ProfileIsPublic==0){
                if (myFriends.filter(f=>f.uid==item.UsrUUID).length>0 || item.UsrUUID==userID){
                  articlesResults.push(item)
                }
              }else{
                if (profileIsPublic==1){
                  articlesResults.push(item)
                }else{
                  if (myFriends.filter(f=>f.uid==item.UsrUUID).length>0 || item.UsrUUID==userID){
                    articlesResults.push(item)
                  }                  
                }
              }
              
          }
        })
        setArticles(articlesResults)
        var articlesResults=[]
        res.data.posts.filter(post=>post.IsStory==1).forEach(item=>{
          if (item.ProfileIsPublic==0){
            if (myFriends.filter(f=>f.uid==item.UsrUUID).length>0 || item.UsrUUID==userID){
              articlesResults.push(item)
            }
          }else{
            if (profileIsPublic==1){
              articlesResults.push(item)
            }else{
              if (myFriends.filter(f=>f.uid==item.UsrUUID).length>0 || item.UsrUUID==userID){
                articlesResults.push(item)
              }                  
            }
          }
        })
        setStories(articlesResults)
        setArticlesFlt(res.data.posts)
        setReactions(res.data.reactions)
        setPostReactions(res.data.postreactions)
        loadUnreadNotifications(userID)
        setIsLoading(false)

      })
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })          
  }


  const loadReactions=()=>{
    //setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'wallarticles/reactions/'+sportID+'/'+langID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
       //console.log(res.data) 

       setReactions(res.data.reactions)
      //setIsLoading(false)
      
    }).catch(err=>{
        //setIsLoading(false)
        console.log(err)
    })     
  }

  const loadPostReactions=()=>{
    //setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'wallarticles/postreactions/'+sportID+'/'+langID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
       //console.log(res.data) 

       setPostReactions(res.data.reactions)
      //setIsLoading(false)
      
    }).catch(err=>{
        //setIsLoading(false)
        console.log(err)
    })     
  }

  const sendPostReaction=(ChallengeID,ChUserID,r)=>{
    const obj={
      ChallengeID,
      ChUserID,
      ReactUserID:userID,
      Rank:r,
      LangID:langID,
      ReactFullName:firstName+" "+lastName
    }


    axios.post(process.env.REACT_APP_API_SERVER+'postreactions',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

      loadPostReactions()

      
    }).catch(err=>{
      console.log(err)

    })


   // 
  }

  const sendReaction=(ChallengeID,ChUserID,r)=>{
    const obj={
      ChallengeID,
      ChUserID,
      ReactUserID:userID,
      Rank:r,
      LangID:langID,
      ReactFullName:firstName+" "+lastName
    }


    axios.post(process.env.REACT_APP_API_SERVER+'challengereactions',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

      loadReactions()

      
    }).catch(err=>{
      console.log(err)

    })


   // 
  }

  const search=()=>{
    if (searchText.length>0){
      //console.log(sportID)
      navigate('/searchresults/'+searchText.trim())
    }
    
  }

  const changeSearchText=(e)=>{
    setSearchText(e.target.value)
  }

  const handleKeyDown=(e)=>{
    if (e.key=='Enter'){
      search()
    }
  }



  const articlesList=articles.map((item,index)=>{
    var articlePhoto=''
    var author=''
    var authorImg=''
    if (item.PostedBy==0){
      articlePhoto=`${process.env.REACT_APP_API_SERVER}/admin/files/${item.UUID}/123`
      author='123High'
      authorImg=otthLogo
    }else{
      author=item.FirstName+ ' '+item.LastName
      authorImg=`${process.env.REACT_APP_API_SERVER}files/${item.UsrUUID}/1234`
      articlePhoto=`${process.env.REACT_APP_API_SERVER}postfiles/${item.UUID}/123`

    }

      return(<HomePost index={index} item={item} articlePhoto={articlePhoto} author={author} authorImg={authorImg} reactions={reactions} postreactions={postreactions} sendReaction={sendReaction} sendPostReaction={sendPostReaction}/>)

  })



  const storiesList=stories.map((item,index)=>{
    if (item.IsChallenge==1){
      var articlePhoto=process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'
    }else{
      if (item.VideoID.length>0){
        var articlePhoto=process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'
      }else{
        var articlePhoto=process.env.REACT_APP_API_SERVER+'postfiles/'+item.UUID+'/123'
      }
      
    }
    
    const endPoint=item.IsChallenge==1 ? 'challengeprofilestory' : 'postprofilestory'
    return(

      <div key={`story${index}`} onClick={()=>navigate('/'+endPoint+'/'+item.UUID+'/'+langID+'/'+item.UsrUUID)}>
          <div className="card rounded" style={{backgroundImage:'url('+articlePhoto+')'}} > 
          <div className="card-img-overlay overlay-text">{item.FirstName} {item.LastName}</div> 
          </div>
      </div>
      )


  })

  const refresh=()=>{
    return new Promise((resolve, reject) => {
      
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'wallarticles/'+sportID+'/'+langID+'/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      let yourDate = new Date()
      const toDay=yourDate.toISOString().split('T')[0]
      loadMyFriends().then(myFriends=>{
        var articlesResults=[]
        res.data.posts.filter(post=>post.IsStory==0).forEach(item=>{
          if (item.Type==1){
            articlesResults.push(item)
          }else{

              if (item.ProfileIsPublic==0){
                if (myFriends.filter(f=>f.uid==item.UsrUUID).length>0){
                  articlesResults.push(item)
                }
              }else{
                if (profileIsPublic==1){
                  articlesResults.push(item)
                }
                
              }
              
          }
        })
        setArticles(articlesResults)
        var articlesResults=[]
        res.data.posts.filter(post=>post.IsStory==1).forEach(item=>{
          if (item.ProfileIsPublic==0){
            if (myFriends.filter(f=>f.uid==item.UsrUUID).length>0){
              articlesResults.push(item)
            }
          }else{
            if (profileIsPublic==1){
              articlesResults.push(item)
            }
          }
        })
        setStories(articlesResults)
        setArticlesFlt(res.data.posts)
        setReactions(res.data.reactions)
        setPostReactions(res.data.postreactions)
        loadUnreadNotifications(userID)
        setIsLoading(false)
        resolve(1)

      })
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })     
    })
  }

  return (
   
    <>
 


      <header>
            <div className="home-upper-section bg-white">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="upper-texts">
                            <div className="text2-g">Hi, {firstName}!</div>
                            <div className="text1-w"><span style={{color:'grey'}}>{sessionStorage.getItem('WelcomeText')}</span></div> 
                        </div>
                    </div>
                    <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')'}}></div>  
                </div>
                <div className="home-post">
                    <div style={{display: 'flex'}}>
                        {/* <div className="home-post-box">
                          <textarea type="text" placeholder="Post something new or search.." onKeyDown={(e)=>handleKeyDown(e)} value={searchText} onChange={(e)=>changeSearchText(e)}></textarea>  
                          <div className="home-post-box-buttons">
                          <button style={{backgroundColor: '#f95d2d', border: '1px solid #f95d2d'}} onClick={()=>search()}><i className="bi bi-search" style={{fontSize: '11px', fontWeight: 'bold', color:'white'}}></i></button>
                          <button style={{backgroundColor: 'black' ,border: '1px solid black'}} onClick={()=>navigate('/homepagepost')}><i className="bi bi-plus" style={{color: '#fff'}}></i></button>
                          </div>
                        </div> */}
                         <div className="home-post-box">
                          <textarea type="text" placeholder="search for a friend with name or nickname" onKeyDown={(e)=>handleKeyDown(e)} value={searchText} onChange={(e)=>changeSearchText(e)}></textarea>  
                          <div className="home-post-box-buttons">
                            <button style={{backgroundColor: '#f95d2d', border: '1px solid #f95d2d'}} onClick={()=>search()}><i className="bi bi-search" style={{fontSize: '11px', fontWeight: 'bold', color:'white'}}></i></button>
                          </div>
                        </div>
                        <div className="home-post-box-buttons ms-3 align-items-center">
                          <button style={{backgroundColor: 'black' ,border: '1px solid black'}} onClick={()=>navigate('/homepagepost')}><i className="bi bi-plus" style={{color: '#fff'}}></i></button>
                        </div> 
                    </div>
                </div>
            </div>                
      </header>

      <PullToRefresh onRefresh={refresh}>
        <section className="section-margin">

          {/* <!-- carousel  --> */}


          <div className="container container-mobile text-center mt-5">  
            <div className="row mx-auto me-auto"> 
              <Carousel 
                responsive={responsive}
                itemClass="carousel-item-padding-40-px"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                containerClass="carousel-container"
              >
                {storiesList}
            </Carousel>
            </div> 
          </div>



           {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <p align="center">
                <img src={sportID==2 ? loadingb : loading} width="100"/>
              </p>
            </div> 
            </>              
            }
            {newChallenge && (!isLoading) &&
              <div onClick={()=>setNewChallenge(false)} class="alert alert-warning alert-dismissible fade show" role="alert" style={{marginLeft:20,marginRight:20}}>
               <b> Your challenge video is under evaluation.</b><hr/>
               Click here to close.
            </div>
            }
            {!isLoading ? articlesList : ''}

        </section>
        </PullToRefresh>
        </>
        

  )
}
