import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import {UserContext} from '../../context/UserContext'
import axios from 'axios'
import moment from 'moment'
import otthLogo from '../../css/images/logo-b-orange.jpg'
import flame from '../../css/images/flame.png'
import thunder from '../../css/images/thunder-black.png'
import achievement from '../../css/images/achievement.png'
import ImageGallery from "react-image-gallery"
import 'react-image-gallery/styles/css/image-gallery.css'
import ReactPlayer from 'react-player'



import cube2 from '../../css/images/cube2.png'
import cube3 from '../../css/images/cube3.png'
import fire2Stop from  '../../css/images/fire2-stop.png'
import thunder3Stop from  '../../css/images/thunder3-stop.png'
import achievement2Stop from '../../css/images/achievement2-stop.png'
import fire2 from '../../css/images/fire2.gif'
import thunder3 from '../../css/images/thunder3.gif'
import achievement2 from '../../css/images/achievement2.gif'
import danger from '../../css/images/danger.png'
import loading from '../../css/images/loading.gif'

export default function HomePost(props) {
    //articlePhoto={articlePhoto} author={author} authorImg={authorImg}/

    const [articlePhoto,setArticlePhoto]=useState(new Image())
    const [authorImg,setAuthorImg]=useState(new Image())
    const [author,setAuthor]=useState('')
    const {userID,setUserID,newVideo,setNewVideo }= useContext(UserContext)
    const [topPosition, setTopPosition] = useState(null);
    const [isLoading,setIsLoading]=useState(false)
    const [photoForFullScreen,setPhotoForFullScreen]=useState('')
    const [reportDetail,setReportDetail]=useState('')
    const [reportSent,setReportSent]=useState(false)
    const [success,setSuccess]=useState(false)
    const [show, setShow] = useState(false);
    const [showVideo,setShowVideo]=useState(true)
    const navigate=useNavigate()
    const vidEl=useRef()
    const elementRef=useRef(null)

    useEffect(()=>{
        //console.log(props.articlePhoto)
        setArticlePhoto(props.articlePhoto)
        setAuthorImg(props.authorImg) 
        setAuthor(props.author)
        checkIfVideoLoaded()

        // if (elementRef.current) {
        //     const topPos = elementRef.current.getBoundingClientRect().top;
        //     setTopPosition(topPos);
        // } 
        
        // setTimeout(()=>{
        //     setIsLoading(false)
        // },2000)
        
        if (props.item.VideoID?.length>0){
          if (newVideo.split('.')[0]==props.item.VideoID){
            setShowVideo(false)
            var checkStatus=setInterval(function(){
              const url=process.env.REACT_APP_VIDEOSERVER_API+'upload/'+props.item.VideoID
              axios.get(url)
              .then(res=>{
                 // console.log(res.data)
                  if (res.data[0].status==1){
                      setShowVideo(true)
                      setNewVideo('')
                      clearInterval(checkStatus)
                  }
              })
          }, 3000);


          }
        }

 

    },[])


    // useEffect(()=>{


    //   const handleBackButton = (event) => {
    //     alert('Back btn')
    //   };

    //   window.addEventListener('popstate', handleBackButton);

    //   return () => {
    //     window.removeEventListener('popstate', handleBackButton);
    //   };      
    // },[photoForFullScreen])


    const checkIfVideoLoaded=()=>{
        // if (props.item.VideoID?.length>0){
        //     axios.get(`https://5f06d0924ebcd.streamlock.net:444/mlg/${props.item.VideoID}.smil/playlist.m3u8#t0.01`).then(res=>{
        //         console.log(res.data)
        //     }).catch(err=>{

        //         console.log(err)
        //     })
        // }
    }

    const reactions=(rankType)=>{
        return(
            props.reactions.filter(r=>r.ChUserID==props.item.PostedBy && r.ChallengeID==props.item.ChallengeID && r.RankType==rankType).length>0 ? props.reactions.filter(r=>r.ChUserID==props.item.PostedBy && r.ChallengeID==props.item.ChallengeID && r.RankType==rankType)[0].v : 0
        )
    }

    const posteactions=(rankType)=>{
        return(
            props.postreactions.filter(r=>r.ChUserID==props.item.PostedBy && r.ChallengeID==props.item.ChallengeID && r.RankType==rankType).length>0 ? props.postreactions.filter(r=>r.ChUserID==props.item.PostedBy && r.ChallengeID==props.item.ChallengeID && r.RankType==rankType)[0].v : 0
        )
    }

    const toggleActive1=(i)=>{
        document.getElementById("fire-animation"+i+"_"+props.index).src=fire2;
        setTimeout(function(){ document.getElementById("fire-animation"+i+"_"+props.index).src=fire2Stop; }, 1500);
      }
    
      const toggleActive2=(i)=>{
        document.getElementById("thunder-animation"+i+"_"+props.index).src=thunder3;
        //setTimeout(function(){ sendReaction(2);document.getElementById("thunder-animation").src=thunder3Stop; }, 1500);
      }
    
      const toggleActive3=(i)=>{
        document.getElementById("achievement-animation"+i+"_"+props.index).src=achievement2;
        //setTimeout(function(){ sendReaction(1);document.getElementById("achievement-animation").src=achievement2Stop; }, 1500);
      }
    

      const makePhotoFullScreen=(photo)=>{
        setPhotoForFullScreen(photo)
      }


      const showModal=()=>{
        setShow(true)
        setReportSent(false)
        setReportDetail('')
        setSuccess(false)

      }

      const toggleModal = () => {
        setShow(!show);
      };


      const sendReport=(e)=>{
        e.preventDefault()
        
        const obj={
            reportDetail,
            contentType:props.item.Type,
            UUID:props.item.UUID,
            UsrUUID:props.item.UsrUUID,
            userID
        }

        axios.post(process.env.REACT_APP_API_SERVER+'report',obj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("token")
                }            
            })
          .then(res=>{

            
            setReportSent(true)
            setSuccess(true)
            setShow(false)
            //console.log(res.data)
      
            
          }).catch(err=>{
            console.log(err)
      
          })
    }


    const Modal = ({ show, onClose, children }) => {
      if (!show) {
        return null;
      }
    
      return (
        <div className="modal-backdrop">
          <div className="modal-content">

            {children}
          </div>
        </div>
      );
    };


  return (
    <>
    {articlePhoto && authorImg && (!isLoading) && showVideo &&

    <div className="home-posts-item" ref={elementRef}>
        <div className="home-avatar-item">
            <div onClick={()=>props.item.UsrUUID.length>0 ?  navigate('/profile/'+props.item.UsrUUID) : null}  className="home-user-photo" style={{backgroundImage:'url('+authorImg+')'}}></div>
            <div className="home-header">
                <div className="home-header-text" onClick={()=>props.item.UsrUUID.length>0 ? navigate('/profile/'+props.item.UsrUUID) : null} >{author}</div>
                <div className="home-header-time d-flex">{moment(props.item.DateCreated).format('DD/MM/YYYY')} 
                {/* {props.item.Timestamp.split('T')[1].substr(0,5)} */}
                <i className="bi bi-globe-americas ps-1" style={{color:'black'}}></i>
                {/* <img className="ps-1" src={cube3} alt="cube icon" style={{height:'14px'}}/> */}
                {props.item.Type==3 &&
                <img className="ps-1" src={cube2} alt="cube icon orange" style={{height:'14px'}}/>       
                }       
                </div>
            </div>     
            <div className="home-challenge-accept">
            {props.item.Type==1 && props.item.IsChallenge==1 &&
                <button
                    onClick={()=>navigate('/challengepost/'+props.item.UUID+'/'+props.item.LangID)} 
                >
                    <img src={cube2} alt="cube icon"/><div className="home-challenge-accept-text"><span style={{fontSize: '11px'}}>Accept</span><span style={{fontSize:'18px', fontWeight: 'bold'}}>+</span></div>
                </button>
            }
            </div> 
        </div>
        <div className="home-practice-days">
            {(props.item.Title.length>0 || props.item.Descr.length>0) &&
                <>
                <b>{props.item.Title}</b><br/><i>{props.item.Descr}</i>
                <br/>
                </>
            }
        </div>

        {props.item.Photo?.length>0 &&
            <> 
            <div onClick={()=>makePhotoFullScreen(articlePhoto)} className="home-post-image" style={{backgroundImage:'url('+articlePhoto+')',backgroundSize:'contain'}}></div>
            {/* <ImageGallery  items={[{original:articlePhoto,thumbnail:articlePhoto}]}
                showThumbnails={false}
                showPlayButton={false}
                
            /> */}
            {photoForFullScreen.length>0 &&
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    zIndex: 9999,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onClick={()=>setPhotoForFullScreen('')}
                >

                <img src={photoForFullScreen}  width="100%"/>
  
            </div>
            }            
            </>
        }

        {props.item.VideoID?.length>0 &&  !(props.item.Type==1 && props.item.IsChallenge==1 ) &&
          <>
            <br/>
            <div className="home-post-video-wrapper" style={{borderWidth:'1px',borderColor:'red'}}>
                <video  ref={vidEl}  controls playsInline width="320" 
                  poster={process.env.REACT_APP_VIDEOSERVER_API+''+props.item.VideoID+'.jpg'}
                  id={`video${props.item.ChallengeID}`}
                >
                <source  
                  src={`https://5f06d0924ebcd.streamlock.net:444/mlg/${props.item.VideoID}.smil/playlist.m3u8#t0.01`} 
                  type="video/mp4"
                  preload="metadata"
                />
                </video>
              
            </div>        
          </>
        }

        <div id={`report${props.index}`} style={{display: show ? 'block' : 'none',marginLeft:20,marginRight:20}}>
          <hr/>
          <h5>Objectionable Content Report</h5>
              <form onSubmit={sendReport}>
                <div className="form-floating mb-3">
                        <input
                        disabled={reportSent}
                        maxLength={250}
                        type="text"
                        className="form-control"
                        id="floatingInput2"
                        placeholder="Mobile phone"
                        required
                        value={reportDetail}
                        onChange={(e)=>setReportDetail(e.target.value)}
                        />
                        {!reportSent &&
                        <label for="floatingInput2">Please Explain</label>
                        }       

                        {reportSent &&
                        <label for="floatingInput2"><b>Report sent to administrator</b></label>
                        }       



                    </div>  


              <div className="row w-100 m-0">
                <div className="col p-0"><button type="button" className="w-100 fw-bold" onClick={()=>setShow(false)}>
                    {!reportSent ? 'Cancel' : 'Close'}
                </button></div>
                {!reportSent &&
                <div className="col p-0"><button type="submit" className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>Send Report</button></div>
                }
                {/* <div className="col p-0"><button type="button" data-bs-dismiss="modal" onClick={()=>navigate('/challengepost/'+challenge.UUID+'/'+challenge.LangID)} className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>OK</button></div> */}
              </div>  
              </form>
              <hr/>
              <br/><br/>
        </div>



        {props.item.Type==3 &&
        <div className="home-post-points">
            <div><img id={`fire-animation3_${props.index}`} src={fire2Stop} alt="flame icon" onClick={()=>{toggleActive1(3);props.sendReaction(props.item.UUID,props.item.UsrUUID,3)}}/>
                {reactions(3)}
            
            </div>
            <div><img id={`thunder-animation3_${props.index}`}  src={thunder3Stop} alt="thunder icon" onClick={()=>{toggleActive2(3);props.sendReaction(props.item.UUID,props.item.UsrUUID,2)}}/>
                {reactions(2)}
            </div>
            <div><img id={`achievement-animation3_${props.index}`}  src={achievement2Stop} alt="achievement icon" onClick={()=>{toggleActive3(3);props.sendReaction(props.item.UUID,props.item.UsrUUID,1)}}/>
                {reactions(1)}
            </div>

            {userID!=props.item.UsrUUID &&
            <div style={{marginLeft:60}}><img  src={danger} alt="danger"  onClick={()=>{showModal()}} />  
            </div>
            }
        </div>
        }
        {props.item.Type==2 &&
        <div className="home-post-points">
            <div><img id={`fire-animation2_${props.index}`} src={fire2Stop} alt="flame icon" onClick={()=>{toggleActive1(2);props.sendPostReaction(props.item.UUID,props.item.UsrUUID,3)}}/>
                {posteactions(3)}
            
            </div>
            <div><img id={`thunder-animation2_${props.index}`}  src={thunder3Stop} alt="thunder icon" onClick={()=>{toggleActive2(2);props.sendPostReaction(props.item.UUID,props.item.UsrUUID,2)}}/>
                {posteactions(2)}
            </div>
            <div><img size="60" id={`achievement-animation2_${props.index}`}  src={achievement2Stop} alt="achievement icon" onClick={()=>{toggleActive3(2);props.sendPostReaction(props.item.UUID,props.item.UsrUUID,1)}}/>
                {posteactions(1)}
            </div>

            {userID!=props.item.UsrUUID &&
            <div style={{marginLeft:60}}><img  src={danger} alt="danger"  onClick={()=>{showModal(props.index)}} />  
            </div>
            }

 
        </div>
        }



    </div>
    }





    {!showVideo &&
      <div className="home-posts-item" ref={elementRef}>

      <div className="home-avatar-item">
            <div onClick={()=>props.item.UsrUUID.length>0 ?  navigate('/profile/'+props.item.UsrUUID) : null}  className="home-user-photo" style={{backgroundImage:'url('+authorImg+')'}}></div>
            <div className="home-header">
                <div className="home-header-text" onClick={()=>props.item.UsrUUID.length>0 ? navigate('/profile/'+props.item.UsrUUID) : null} >{author}</div>
                <div className="home-header-time d-flex">{moment(props.item.DateCreated).format('DD/MM/YYYY')} 
                {/* {props.item.Timestamp.split('T')[1].substr(0,5)} */}
                <i className="bi bi-globe-americas ps-1" style={{color:'black'}}></i>
                {/* <img className="ps-1" src={cube3} alt="cube icon" style={{height:'14px'}}/> */}
                {props.item.Type==3 &&
                <img className="ps-1" src={cube2} alt="cube icon orange" style={{height:'14px'}}/>       
                }       
                </div>
            </div>     
            <div className="home-challenge-accept">
            {props.item.Type==1 && props.item.IsChallenge==1 &&
                <button
                    onClick={()=>navigate('/challengepost/'+props.item.UUID+'/'+props.item.LangID)} 
                >
                    <img src={cube2} alt="cube icon"/><div className="home-challenge-accept-text"><span style={{fontSize: '11px'}}>Accept</span><span style={{fontSize:'18px', fontWeight: 'bold'}}>+</span></div>
                </button>
            }
            </div> 
        </div>

        <div className="home-post-video-wrapper" style={{borderWidth:'1px',borderColor:'red'}}>
        Processing your video, please wait...
      </div>
      </div>
    
    }


    {(!articlePhoto || !authorImg) &&
    <>Loading...</>


    }
    </>
  )
}
