import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import burnBg from '../../css/images/burn-bg.png'
import burnBlackOrange from '../../css/images/burn-black-orange.png'
import man2 from '../../css/images/man2.jpg'
import man from '../../css/images/man.jpg'
import man3 from '../../css/images/man3.jpg'
import burnWhite from '../../css/images/burn-white.png'
import flame from '../../css/images/flame.png'
import woman from '../../css/images/woman.jpg'
import {UserContext} from '../../context/UserContext'
import leftArrow from  '../../css/images/left-arrow.png'

export default function Chat() {

    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const {sportID,langID}= useContext(UserContext)
    const [sports,setSports]=useState([])
    const [countries,setCountries]=useState([])
    const [positions,setPositions]=useState([])
    const [usr,setUsr]=useState({})
    const [profileSaved,setProfileSaved]=useState(0)
    const [reactions,setReactions]=useState([])
    const [challenge,setChallenge]=useState({})
    const [challenges,setChallenges]=useState([])
    const [friendShip,setFriendShip]=useState([])
    const [stories,setStories]=useState([])
    const [photos,setPhotos]=useState([])
    const [numOfPosts,setNumOfPosts]=useState(0)
    const {socket,setSocket}=useContext(UserContext)
    const [rooms,setRooms]=useState([])
    const [unreadPerRoom,setUnreadPerRoom]=useState([])
    const { loadUnreadNotifications}=useContext(UserContext)
    const navigate=useNavigate()


    useEffect(()=>{
      socket.on("chat-notification",ReceiverUUID=>{
        //alert(ReceiverUUID)
        loadRooms()
      })
    },[socket])

    useEffect(()=>{
        loadRooms()
        
      },[])
    
      const loadRooms=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'messenger/rooms/'+userID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
          setRooms(res.data.rooms)
          loadUnreadMessagesPerRoom(userID)
          loadUnreadNotifications(userID)
        }).catch(err=>{
            setIsLoading(false)
    
        })      
      }


      const removeRoom=(RoomUUID)=>{
        // if (window.confirm('Are you sure you want to remove this conversation?')){

        // }
      }


      const loadUnreadMessagesPerRoom=(uuid)=>{
        axios.get(process.env.REACT_APP_API_SERVER+'messenger/messages/unreadperroom/'+uuid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
          //console.log(res.data)
          setUnreadPerRoom(res.data)
          setIsLoading(false)
        }).catch(err=>{
          console.log(err)
        })
      }

      const enterRoom=async (roomid)=>{
        await socket.emit('join-room',roomid)
        navigate('/chatroom/'+roomid)
      }

      const roomsList=rooms.map((item,index)=>{

        return(
            <div key={`room${index}`} className="chat-avatar-item" onClick={()=>enterRoom(item.RoomUUID)}>

                <div className="chat-user-photo" style={{backgroundImage:'url('+`${process.env.REACT_APP_API_SERVER}files/${item.ChatWithUUID}/1234`+')'}}></div>
                <div className="chat-header">
                    <div className="chat-header-text">{item.ChatWithName}</div>
                    <div className="chat-header-time">{moment(item.DateCreated.split('T')[0]).format('DD/MM/YYYY')}</div>
                    {unreadPerRoom.filter(xitem=>xitem.RoomID==item.RoomUUID).length>0 &&
                    <div className="chat-header-time"><font color="red">{unreadPerRoom.filter(xitem=>xitem.RoomID==item.RoomUUID)[0].c} Unread Messages</font></div>
                    }
                   {/* <div style={{marginTop:20}} className="chat-header-time"><button onClick={()=>removeRoom(item.RoomUUID)} className="btn btn-secondary">Remove Conversation</button></div> */}
                </div>
                   
                    
            </div>            
        )
      })

  return (
    <>
    

    <div className="container container-mobile bg-grey">
      <header>
          <div className="upper-section upper-dark">
            <div className="d-flex align-items-center">
              <div className="upper-arrow">
                {/* <button><img src={leftArrow}/></button> */}
              </div>
              <div className="upper-texts">
                <div className="text1-w">Chat</div>
                <div className="text2-g2">with your friends</div>
              </div>
            </div>

          </div>    
      </header>



        <section className="section-margin">
            {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
            }


            {!isLoading &&
            <>
              {rooms.length>0 &&
               <>{roomsList}</>
              }  

              {rooms.length==0 &&
                <>
                
                <div className="home-posts-item">
                  <div className="alert alert-secondary" role="alert">
                  No chat results
                </div>  
                </div> 
                </>   
              }          
            
            </>
            }
        </section>


    </div>








    </>
  )
}
