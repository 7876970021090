import React,{useContext, useEffect} from 'react'
import axios from 'axios'
import {Route,Navigate,useParams} from 'react-router-dom'
import Footer from './Components/Footer'
import Footer2 from './Components/Footer2'
import Footer3 from './Components/Footer3'
import {UserContext} from '../context/UserContext'
//import Header from './components/protected/Header'
// import {Container,Row,Col} from 'react-bootstrap'
// import DashboardNav from './DashboardNav'
// import DashboardFooter from './DashboardFooter'


export default function ProtectedRoute(params) {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {setToken}=useContext(UserContext)

    useEffect(()=>{
        if (isAuth || (sessionStorage.getItem("isAuth")==1)){
            setIsAuth(true)
        }
    },[])

    // if( navigator.userAgent.includes ('wv')){
    //     return (
    //     (isAuth || (sessionStorage.getItem("isAuth")==1)) ? <><div className="container container-mobile bg-grey" style={{paddingBottom: '40px'}}><params.Component/><Footer/></div></> : <Navigate to='/login'/>
    //     )
    // }else{

    if (params.nofooter){
    
        return (
            (isAuth || (sessionStorage.getItem("isAuth")==1)) ? <><div className="container container-mobile" ><params.Component/></div></> : <Navigate to='/login'/>

        )
    }else{
        return (
            (isAuth || (sessionStorage.getItem("isAuth")==1)) ? <><div className="container container-mobile bg-grey" style={{paddingBottom: '40px'}}><params.Component/><Footer3/></div></> : <Navigate to='/login'/>

        )
    }


   // }
}