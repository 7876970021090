import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import burnBg from '../../css/images/burn-bg.png'
import burnBlackOrange from '../../css/images/burn-black-orange.png'
import man2 from '../../css/images/man2.jpg'
import man from '../../css/images/man.jpg'
import man3 from '../../css/images/man3.jpg'
import burnWhite from '../../css/images/burn-white.png'
import flame from '../../css/images/flame.png'
import woman from '../../css/images/woman.jpg'
import {UserContext} from '../../context/UserContext'
import leftArrow from  '../../css/images/left-arrow.png'
import axios from 'axios'
import moment from 'moment'

export default function Notifications() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(true)
    const {sportID,langID}= useContext(UserContext)
    const [sports,setSports]=useState([])
    const [countries,setCountries]=useState([])
    const [positions,setPositions]=useState([])
    const [wallOfFame,setWallOfFame]=useState([])
    const [profileSaved,setProfileSaved]=useState(0)
    const [notifications,setNotifications]=useState([])
    const [friendAprovals,setFriendAprovals]=useState([])
    const [usersWhoBlockedMe,setUsersWhoBlockedMe]=useState([])
    const { loadUnreadNotifications}=useContext(UserContext)

    const navigate=useNavigate()

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'notifications/friends/'+userID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
    
           setNotifications(res.data.results)
           loadAprovals()
           updateAprovals()
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
        }) 
    }

    const loadAprovals=()=>{
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'notifications/friendaprovals/'+userID,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{
  
        setFriendAprovals(res.data.results)
         setIsLoading(false)
      }).catch(err=>{
          setIsLoading(false)
      }) 
  }


  const updateAprovals=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'notifications/friendaprovalsupdate/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

      loadUnreadNotifications(userID)
       setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)
    }) 
}


const removeFriend=(SenderUUID,notificationID)=>{
  const obj={
    SenderUUID,
    ReceiverUUID:userID,
    NotificationID:notificationID
  }
//console.log(obj)
  axios.post(process.env.REACT_APP_API_SERVER+'friends/removewithnotification',obj,{
    headers: {
      'Authorization': 'Bearer '+sessionStorage.getItem("token")
      }  
  }).then(res=>{
    if (res.data.err==0){
      loadData()
    }else{
      alert('An error has occured')
    }
  })

}


const aproveFriendship=(SenderUUID,notificationID)=>{
  // console.log(uuid)
   setIsLoading(true)
   axios.get(process.env.REACT_APP_API_SERVER+`friends/aprovewithnotification/0/${SenderUUID}/${userID}/${notificationID}`,{
     headers: {
         'Authorization': 'Bearer '+sessionStorage.getItem("token")
         }            
     })
   .then(res=>{
     loadData()
 
      setIsLoading(false)
   }).catch(err=>{
       setIsLoading(false)
   }) 
 }


    // const notificationsList=notifications.map(item=>{
    //     const authorImg=`${process.env.REACT_APP_API_SERVER}files/${item.SenderUUID}/1234`
    //     if (usersWhoBlockedMe.filter(u=>u.UserWhoBlockedMeUUID==item.SenderUUID).length==0){
    //     return(
    //         <div className="chat-avatar-item">
    //         <div onClick={()=>navigate('/profile/'+item.SenderUUID)} className="chat-user-photo" style={{backgroundImage:'url('+authorImg+')'}}></div>
    //         <div className="chat-header">
    //             <div className="chat-header-text">{item.FirstName} {item.LastName} sent you a friend request. <br/>
    //             Please visit user's profile to accept or deny the request.</div>
    //             <div className="chat-header-time">{moment(item.DateCreated).format('DD/MM/YYYY')} </div>
    //             {/* <button className="btn btn-success">Accept</button>
    //             <br/>
    //             <button className="btn btn-danger">Deny</button>
    //             <hr/> */}
    //         </div>      
    //     </div>
    //     )
    //   }
    // })

    const friendAprovalsList=friendAprovals.map(item=>{
      //console.log(item)
      const authorImg=`${process.env.REACT_APP_API_SERVER}files/${item.SenderUUID}/1234`
      //if (usersWhoBlockedMe.filter(u=>u.UserWhoBlockedMeUUID==item.SenderUUID).length==0){
      return(
          <div className="chat-avatar-item">
          <div onClick={()=>navigate('/profile/'+item.SenderUUID)} className="chat-user-photo" style={{backgroundImage:'url('+authorImg+')'}}></div>
        
          <div className="chat-header">
              <div className="chat-header-text" dangerouslySetInnerHTML={{__html: item.Text}}></div>
              <div className="chat-header-time">{moment(item.XTimestamp.split('T')[0]).format('DD/MM/YYYY')}  {item.XTimestamp.split('T')[1].substr(0,5)}</div>
              {item.Text.includes('sent you a friend request') &&
              <>
              <button onClick={()=>aproveFriendship(item.SenderUUID,item.id)} className="btn btn-success" style={{backgroundColor:'#76b595'}}>Accept</button>
              <br/>
              <button onClick={()=>removeFriend(item.SenderUUID,item.id)} className="btn btn-danger" style={{backgroundColor:'#cf522b'}}>Deny</button>
              <hr/>
              </>
              }
          </div>      
      </div>
      )
   // }
  })
  return (
    <div class="container container-mobile bg-grey">
      <header>
    <div className="upper-section upper-dark">
    <div className="d-flex align-items-center">
      <div className="upper-arrow">
        <button onClick={()=>navigate(-1)}><img src={leftArrow}/></button>
      </div>
      <div className="upper-texts">
        <div className="text1-w">Check</div>
        <div className="text2-g2">your notifications...</div>
      </div>
    </div>

  </div>    
</header>

<section className="section-margin">


    {!isLoading &&
    <>
    {(notifications.length>0 || friendAprovals.length>0)  &&
      <>
        {friendAprovalsList}
        {/* {notificationsList} */}
      </>
    }

    {notifications.length==0 && friendAprovals.length==0 &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              No notifications
            </div>  
            </div> 
            </>  
    }

    </>
    }



      {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
     }

</section>

</div>


  )
}
