import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import {UserContext} from '../../context/UserContext'
import moment from 'moment'


import Footer from '../Components/Footer'
import axios from 'axios'

export default function MenuMe() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const {sportID,langID}= useContext(UserContext)
    const [sports,setSports]=useState([])
    const [countries,setCountries]=useState([])
    const [positions,setPositions]=useState([])
    const [usr,setUsr]=useState({})
    const [profileSaved,setProfileSaved]=useState(0)
    const navigate=useNavigate()
    const [maxRegDate,setMaxRegDate]=useState(null)

    useEffect(()=>{
        loadData()
        loadSports()
        loadCountries()
        loadPositions()

        window.scrollTo({ top: 0, behavior: 'smooth' })

        let present = new Date()
        let presentDay= present.toISOString().split('T')[0]
        setMaxRegDate(moment(presentDay).subtract(13,'years').format('YYYY-MM-DD'))
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'userprofile/'+userID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{

           setUsr(res.data.results)
            console.log(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            //console.log(err)
        })          
    }

      const loadPositions=()=>{
        axios.get(process.env.REACT_APP_API_SERVER+'positions',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("token")
                }            
            })        
          .then(res=>{
      
              if (res.data.err==0){
               setPositions(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
              //console.log(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
              }else{
                  alert(res.data.msg)
              }
      
          }).catch(err=>{
            alert("An error has occured, please try again")
          })         
      }


      const loadSports=()=>{
        axios.get(process.env.REACT_APP_API_SERVER+'sports')
          .then(res=>{
      
              if (res.data.err==0){
               setSports(res.data.results)
              // console.log(res.data.results)
              }else{
                  alert(res.data.msg)
              }
      
          }).catch(err=>{
            alert("An error has occured, please try again")
          })         
      }
      
      
      const loadCountries=()=>{
        axios.get(process.env.REACT_APP_API_SERVER+'countries')
          .then(res=>{
      
              if (res.data.err==0){
               setCountries(res.data.results)
               //console.log(res.data.results)
              }else{
                  alert(res.data.msg)
              }
      
          }).catch(err=>{
            alert("An error has occured, please try again")
          })         
      }

      const sportsList=sports.map(item=>{
        return(
          <option key={item.SportUUID} value={item.SportID}>{item.SportName}</option>
        )
      })
      
      
      const countriesList=countries.map(item=>{
        return(
          <option key={item.UUID} value={item.CountryID}>{item.Country}</option>
        )
      })


      const positionsList=positions.map(item=>{
        return(
          <option key={item.PositionLangID+'pl'} value={item.PositionID}>{item.PositionLangTitle}</option>
        )
      })


    const saveProfile=(e)=>{
        e.preventDefault()
        const obj={...usr}
        obj.id=userID

        axios.post(process.env.REACT_APP_API_SERVER+'userprofile',obj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })        
        .then(res=>{
    
          if (res.data.err==0){
            
            setProfileSaved(1)
            navigate('/menu')
          }else{
            setProfileSaved(2)
          }

          //setTimeout(setProfileSaved(0),2000)
    
        }).catch(err=>{
          alert("An error has occured, please try again")
        })         

    }

  return (
    <>
      <header>
      <div className="upper-section upper-dark">
        <div className="d-flex align-items-center">
            {/* <div className="upper-arrow">
                <button><img src="images/left-arrow.png"/></button>
              </div> */}
          <div className="upper-texts">
            <div className="text1-w">personal details</div>
            <div className="text2-g2">{firstName} {lastName}</div>
          </div>
        </div>
        <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')'}}></div>
      </div>    
  </header>

  <section className="pb-4 section-margin">
  <div className="container container-mobile">
      <div className="div-wrap">
        
        {isLoading &&
          <div className="alert alert-warning" role="alert">
          Loading, please wait...
          </div>          
        }
        
        {!isLoading &&
        <div className="form-div2">

          <form onSubmit={saveProfile}>
          <div className="alert alert-secondary" role="alert">
            <b>Update your info</b>
            </div>

          <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput21"
                placeholder="First Name"
                required
                value={usr.FirstName}
                maxLength={32}
                onChange={(e)=>setUsr({...usr,FirstName:e.target.value})}
              />
              <label for="floatingInput21">First Name</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput21"
                placeholder="Last Name"
                required
                maxLength={64}
                value={usr.LastName}
                onChange={(e)=>setUsr({...usr,LastName:e.target.value})}
              />
              <label for="floatingInput21">Last Name</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="tel"
                maxLength={20}
                className="form-control"
                id="floatingInput2"
                placeholder="Mobile phone"
                value={usr.MobilePhone}
                onChange={(e)=>setUsr({...usr,MobilePhone:e.target.value})}                
              />
              <label for="floatingInput2">Mobile Phone</label>
            </div>



            <div className="form-floating mb-3">
              <input
                type="text"
                maxLength={32}
                className="form-control"
                id="floatingInput3"
                placeholder="Nickname"
                //required
                value={usr.Nickname}
                onChange={(e)=>setUsr({...usr,Nickname:e.target.value})}                    
              />
              <label for="floatingInput3">Nickname</label>
            </div>

            <div className="form-floating mb-3">
              <input
              disabled={true}
                type="date"
                className="form-control"
                max={maxRegDate}
                id="floatingInput4"
                placeholder="DD/MM/YY"
                required
                value={usr.DateOfBirthYMD}
                onChange={(e)=>setUsr({...usr,DateOfBirthYMD:e.target.value})}     
              />
              <label for="floatingInput4">Date of Birth</label>
            </div>


            <div className="form-floating mb-3">
              <input
                type="number"
                step={1}
                min={0}
                max={300}
                className="form-control"
                id="floatingInput36"
                placeholder="Height"
                //required
                value={usr.Height}
                onChange={(e)=>setUsr({...usr,Height:e.target.value})}                    
              />
              <label for="floatingInput36">Height (in centimeters)</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="number"
                step={1}
                min={0}
                max={300}
                className="form-control"
                id="floatingInput37"
                placeholder="Weight (kgr)"
                //required
                value={usr.Weight}
                onChange={(e)=>setUsr({...usr,Weight:e.target.value})}                    
              />
              <label for="floatingInput37">Weight (kgr)</label>
            </div>



            <div className="form-floating mb-3">
              <select
                className="form-select form-control mb-3"
                aria-label="Select Sport"
                id="sportSelected"
                required
                value={usr.LeftRight}
                onChange={(e)=>setUsr({...usr,LeftRight:e.target.value})}    
              >
                <option value='1'>Right Handed/Footed</option>
                <option value='2'>Left Handed/Footed</option>
                <option value='3'>Both L-R Handed/Footed</option>
            
              </select>
            </div>


            <div className="form-floating mb-3">
              <select
                className="form-select form-control mb-3"
                aria-label="Country"
                id="countrySelected"
                required
                value={usr.CountryID}
                onChange={(e)=>setUsr({...usr,CountryID:e.target.value})}    
              >
                {countriesList}
            
              </select>
            </div>

            <div className="form-floating mb-3">
              <select
                className="form-select form-control mb-3"
                aria-label="Position"
                id="positionSelected"
                
                value={usr.PositionID}
                onChange={(e)=>setUsr({...usr,PositionID:e.target.value})}    
              >
                <option value='0'>[Select Position]</option>
                {positionsList}
            
              </select>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                maxLength={32}
                className="form-control"
                id="floatingInput344"
                placeholder="Team"
                //required
                value={usr.Team}
                onChange={(e)=>setUsr({...usr,Team:e.target.value})}                    
              />
              <label for="floatingInput344">Team</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                maxLength={250}
                className="form-control"
                id="floatingInput344"
                placeholder="Previous Teams "
                //required
                value={usr.PreviousTeams}
                onChange={(e)=>setUsr({...usr,PreviousTeams:e.target.value})}                    
              />
              <label for="floatingInput344">Previous Teams (comma seperated)</label>
            </div>


            <div className="form-floating mb-3">
              <input
                type="text"
                maxLength={10}
                className="form-control"
                id="floatingInput344"
                placeholder="Favourite Number"
                //required
                value={usr.FavouriteNumber}
                onChange={(e)=>setUsr({...usr,FavouriteNumber:e.target.value})}                    
              />
              <label for="floatingInput344">Favourite Number</label>
            </div>


            <div className="form-floating mb-3">

                <textarea 
                  className="form-control" 
                  id="exampleFormControlTextarea1" 
                  rows="10" 
                  onChange={(e)=>setUsr({...usr,PersonalInfo:e.target.value})}  >
                    {usr.PersonalInfo}
                  </textarea>
                <label for="floatingInput344">Brief Personal Info</label>
            </div>



            <button type="submit" className="btn orange mt-3">Save Profile</button>

            {profileSaved==1 &&
            <>
            <br/><br/>
            <div className="alert alert-success" role="alert">
            Profile Updated
            </div>
            </>          
            }   
            {profileSaved==2 &&
            <>
            <br/><br/>
            <div className="alert alert-danger" role="alert">
            An error has occured, please try again
            </div>
            </>          
            } 


          </form>
        </div>
        }
      </div>
    </div>  


  </section>
  </>
  )
}
