import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import Flag from 'react-world-flags'
import axios from 'axios'
import {UserContext} from '../../../context/UserContext'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import leftArrow from '../../../css/images/left-arrow.png'

export default function ProfileNew() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const {sportID,langID}= useContext(UserContext)
    const [sports,setSports]=useState([])
    const [countries,setCountries]=useState([])
    const [positions,setPositions]=useState([])
    const [usr,setUsr]=useState({})
    const [profileSaved,setProfileSaved]=useState(0)
    const [reactions,setReactions]=useState([])
    const [challenge,setChallenge]=useState({})
    const [challenges,setChallenges]=useState([])
    const [friendShip,setFriendShip]=useState([])
    const [stories,setStories]=useState([])
    const [photos,setPhotos]=useState([])
    const [videos,setVideos]=useState([])
    const [numOfPosts,setNumOfPosts]=useState(0)
    const {socket,setSocket}=useContext(UserContext)
    const [addFriendBtnVis,setAddFriendBtnVis]=useState(true)
    const {itemToView,setItemToView}= useContext(UserContext)
    const navigate=useNavigate()
    const {uid}=useParams()


    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3
        }
      };
    
      useEffect(()=>{
        loadData()
        loadSports()
        loadCountries()
        loadPositions()
        loadStories()
        //checkIfOnline()
        // window.scrollTo({ top: 0, behavior: 'smooth' })
    
        let items = document.querySelectorAll('.carousel .carousel-item')

        items.forEach((el) => {
            const minPerSlide = 5
            let next = el.nextElementSibling
            for (var i=1; i<minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                  next = items[0]
                }
                let cloneChild = next.cloneNode(true)
                el.appendChild(cloneChild.children[0])
                next = next.nextElementSibling
            }
        })
    },[])
    
    useEffect(()=>{
      if (stories.length>0){
        let items = document.querySelectorAll('.carousel .carousel-item')
        //console.log(items)
        items.forEach((el) => {
            const minPerSlide = 5
            let next = el.nextElementSibling
            for (var i=1; i<minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                  next = items[0]
                }
                let cloneChild = next.cloneNode(true)
                el.appendChild(cloneChild.children[0])
                next = next.nextElementSibling
            }
        })
      }
    },[stories])
    
    // useEffect(()=>{
    
    //   let items = document.querySelectorAll('.carousel .carousel-item')
    
    //   items.forEach((el) => {
    //       const minPerSlide = 5
    //       let next = el.nextElementSibling
    //       for (var i=1; i<minPerSlide; i++) {
    //           if (!next) {
    //               // wrap carousel by using first child
    //             next = items[0]
    //           }
    //           let cloneChild = next.cloneNode(true)
    //           el.appendChild(cloneChild.children[0])
    //           next = next.nextElementSibling
    //       }
    //   })
    // },[challenges])
    
      const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'userprofile/'+uid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
            const yearNow=new Date().getFullYear()
            res.data.results.Age=yearNow-res.data.results.DateOfBirthYMD.split('-')[0]
            //console.log(res.data.results)
           setUsr(res.data.results)
            if (uid!=userID){
    
              checkFrinedShip()
            }
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        })          
    }
    
    
    const checkFrinedShip=()=>{
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'checkfriendship/'+userID+'/'+uid,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })        
      .then(res=>{
    
          if (res.data.err==0){
            //console.log(res.data.results)
            setFriendShip(res.data.results)
            setAddFriendBtnVis(true)
          }else{
              alert(res.data.msg)
          }
          setIsLoading(false)
      }).catch(err=>{
        alert("An error has occured, please try again")
        setIsLoading(false)
      })  
    }
    
    const loadPositions=()=>{
      axios.get(process.env.REACT_APP_API_SERVER+'positions',{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })        
        .then(res=>{
    
            if (res.data.err==0){
             setPositions(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
            //console.log(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
            }else{
                alert(res.data.msg)
            }
    
        }).catch(err=>{
          alert("An error has occured, please try again")
        })         
    }
    
    
    const loadSports=()=>{
      axios.get(process.env.REACT_APP_API_SERVER+'sports')
        .then(res=>{
    
            if (res.data.err==0){
             setSports(res.data.results)
            // console.log(res.data.results)
            }else{
                alert(res.data.msg)
            }
    
        }).catch(err=>{
          alert("An error has occured, please try again")
        })         
    }
    
    
    const loadCountries=()=>{
      axios.get(process.env.REACT_APP_API_SERVER+'countries')
        .then(res=>{
    
            if (res.data.err==0){
             setCountries(res.data.results)
            // console.log(res.data.results)
            }else{
                alert(res.data.msg)
            }
    
        }).catch(err=>{
          alert("An error has occured, please try again")
        })         
    }
    
    
    const loadStories=()=>{
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'profilestories/'+uid,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{
          //console.log(res.data.posts.filter(post=>post.IsStory==1).slice(0, 5)) 
          setStories(res.data.posts.filter(post=>post.IsStory==1).slice(0, 5))
          //setStories(res.data.posts)
          setChallenges(res.data.posts.filter(post=>post.IsChallenge==1))
          setPhotos(res.data.posts.filter(post=>post.IsStory==0))
          setVideos(res.data.posts.filter(post=>post.VideoID.length>0))
          //console.log(res.data.posts.filter(post=>post.VideoID.length>0))
          setNumOfPosts(res.data.posts.length)
         //setReactions(res.data.reactions)
         setIsLoading(false)
      }).catch(err=>{
        console.log(err)
          setIsLoading(false)
      })          
    }
    
    
    
    const addFriend=(ReceiverUUID,Email)=>{
      setAddFriendBtnVis(false)
      setIsLoading(true)
      const obj={
        SenderUUID:userID,
        ReceiverUUID,
        SenderName: firstName+' '+lastName,
        Email:usr.Email
      }
    
    //  console.log(obj)
      axios.post(process.env.REACT_APP_API_SERVER+'friends/add',obj,{
        headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }  
      }).then(res=>{
        if (res.data.err==0){
        //console.log(res.data)
          loadData()
        }else{
          alert('An error has occured')
        }
      })
    
    }
    
    const loadImage=(id)=>{
      const d=Date.now()
      //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
      return(`${process.env.REACT_APP_API_SERVER}files/${id}/${d}`)
    }
    
    const viewItem=(itemUUID)=>{
      navigate('/home2profile/'+uid+'/'+itemUUID)
    }
    
    const viewItemStory=(item)=>{
      const itemObj={
        Type:item.Type,
        ItemUUID:item.UUID,
        ItemID:item.ChallengeID,
        VideoID:item.VideoID,
        Photo:item.Photo,
        UsrUUID:item.UsrUUID,
        UsrName:item.FirstName + " " + item.LastName,
        Descr:item.Descr,
        Title:item.Title,
        Reactions:item.reactions,
        Page:'/home'
      }
    
      var url=''
      if (item.Type==2){
        url=process.env.REACT_APP_API_SERVER+'postreactions/profile/'+item.UUID
      }
    
      if (item.Type==3){
        url=process.env.REACT_APP_API_SERVER+'challengereactions/profile/'+item.UUID
      }
    
      axios.get(url,{
        headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }  
        }).then(res=>{
    
    
          const itemObj={
            Type:item.Type,
            ItemUUID:item.UUID,
            ItemID:item.ChallengeID,
            VideoID:item.VideoID,
            Photo:item.Photo,
            UsrUUID:item.UsrUUID,
            UsrName:item.FirstName + " " + item.LastName,
            Descr:item.Descr,
            Title:item.Title,
            Reactions:res.data,
            Page:'/profile/'+item.UsrUUID
          }
    
    
          setItemToView(itemObj)
          navigate('/content')
    
    
    
        }).catch(err=>{
          alert("An error has occured, please try again")
        })   
    
    
    }
    

    const storiesList=stories.map((item,index)=>{
      //console.log(item)
      if (item.IsChallenge==1){
        var articlePhoto=process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'
      }else{
        if (item.VideoID.length>0){
          var articlePhoto=process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID+'.jpg'
        }else{
          var articlePhoto=process.env.REACT_APP_API_SERVER+'postfiles/'+item.UUID+'/123'
        }      
      }
      
      const endPoint=item.IsChallenge==1 ? 'challengeprofilestory' : 'postprofilestory'
    
      return(
        <div key={`aricle${index}`} onClick={()=>viewItemStory(item)}>
       {/* <div key={`aricle${index}`} onClick={()=>navigate('/'+endPoint+'/'+item.UUID+'/'+langID+'/'+item.UsrUUID)}> */}
            <div className="card rounded" style={{backgroundImage:'url('+articlePhoto+')'}} > 
    
            </div>
        </div>
      )
    })


    
    const photosList=photos.map((item,index)=>{
      var item1=null,item2=null,item3=null
      if ((index+1)%3==1){
        try {item1=photos[index]}catch(err){}
        try {item2=photos[index+1]}catch(err){}
        try {item3=photos[index+2]}catch(err){}

        try {
        if (item1.IsChallenge==1){
          var articlePhoto1=process.env.REACT_APP_VIDEOSERVER_API+''+item1.VideoID+'.jpg'
        }else{
          if (item1.VideoID.length>0){
            var articlePhoto1=process.env.REACT_APP_VIDEOSERVER_API+''+item1.VideoID+'.jpg'
          }else{
            var articlePhoto1=process.env.REACT_APP_API_SERVER+'postfiles/'+item1.UUID+'/123'
          }      
        }
      }catch(err){}

      try {
        if (item2.IsChallenge==1){
          var articlePhoto2=process.env.REACT_APP_VIDEOSERVER_API+''+item2.VideoID+'.jpg'
        }else{
          if (item2.VideoID.length>0){
            var articlePhoto2=process.env.REACT_APP_VIDEOSERVER_API+''+item2.VideoID+'.jpg'
          }else{
            var articlePhoto2=process.env.REACT_APP_API_SERVER+'postfiles/'+item2.UUID+'/123'
          }      
        }
      }catch(err){}

      try {
        if (item3.IsChallenge==1){
          var articlePhoto3=process.env.REACT_APP_VIDEOSERVER_API+''+item3.VideoID+'.jpg'
        }else{
          if (item3.VideoID.length>0){
            var articlePhoto3=process.env.REACT_APP_VIDEOSERVER_API+''+item3.VideoID+'.jpg'
          }else{
            var articlePhoto3=process.env.REACT_APP_API_SERVER+'postfiles/'+item3.UUID+'/123'
          }      
        }
      }catch(err){}


      

        return(
          <div className="row ms-2" key={`pppp${index}`}>
                {item1 &&
                <div onClick={()=>viewItem(item1.UUID)} className="col-sm-4 profile-user-posts" style={{backgroundImage:'url('+articlePhoto1+')'}}></div>
                }
                {item2 &&
                <div onClick={()=>viewItem(item2.UUID)} className="col-sm-4 profile-user-posts" style={{backgroundImage:'url('+articlePhoto2+')'}}></div>
                }
                {item3 &&
                <div onClick={()=>viewItem(item3.UUID)} className="col-sm-4 profile-user-posts" style={{backgroundImage:'url('+articlePhoto3+')'}}></div>            
                }
            </div>
        )
      
      }


    })
    
    const aproveFriendship=(uuid)=>{
     // console.log(uuid)
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'friends/aprove/'+uuid+'/'+uid+'/'+userID,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{
        loadData()
    
         setIsLoading(false)
      }).catch(err=>{
          setIsLoading(false)
      }) 
    }
    
    const blockUser=()=>{
      const obj={
        UserID:userID,
        BlockedID:usr.id,
        action:1
      }
    
      axios.post(process.env.REACT_APP_API_SERVER+'block',obj,{
        headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }  
      }).then(res=>{
        if (res.data.err==0){
          navigate('/blocks')
          //loadData()
        }else{
          alert('An error has occured')
        }
      })
    }
    
    
    
      const createRoom=()=>{
        const obj={
          HostID:userID,
          GuestID:uid
        }
    
        axios.post(process.env.REACT_APP_API_SERVER+'messenger/rooms',obj,{
          headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }  
        }).then(async res=>{
         // console.log(res.data)
          if (res.data.err==0){
            await socket.emit('join-room',res.data.RoomIDUUID)
            navigate('/chatroom/'+res.data.RoomIDUUID)
    
          }else{
            alert('An error has occured')
          }
        })
      }

  return (
    <div className="container container-mobile  p-0 pb-3">
      <header>
          <div className="profile3-upper-section upper-dark" style={{height: '50px'}}>
            <div className="d-flex align-items-center">
              <div className="upper-arrow">
              <button onClick={()=>navigate('/home')}><img src={leftArrow}/></button>
              </div>
              <div className="upper-texts">
                <div className="text1-w">{usr.FirstName} {usr.LastName}</div>
              </div>
            </div>
            <div className="pe-3"><i className="bi bi-sliders sliders-icon" onClick={()=>navigate('/menu')}></i></div>
          </div>    
      </header>

    <>
        {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
        }
        {!isLoading &&
        <>
        <section className="profile3-bg d-flex">

            <div className="profile3-left-section">
                <div className="profile3-user">
                    <div className="profile3-user-photo">
                    <div className="profile3-user-image"  style={{backgroundImage: 'url('+loadImage(usr.id)+')'}}></div>
                    </div>
                    <div className="profile3-stats d-flex">
                    <div className="profile3-posts-stats ">
                        <div className="fw-bold">{numOfPosts}</div>
                        <div style={{fontSize:'10px'}}>Posts</div>
                    </div>
                    <div className="profile3-challenges-stats">
                        <div className="fw-bold">{challenges.length}</div>
                        <div style={{fontSize:'10px'}}>Challenges</div>
                    </div>
                    </div>
                </div>
                <div className="profile-current-team mt-3">
                    <span style={{fontSize: '12px', color:'#f95d2d'}}>{usr.Team}</span>
                    <span style={{color:'white', fontSize: '12px'}}>{positions.filter(item=>item.PositionID==usr.PositionID)[0]?.PositionLangTitle}</span>
                </div>
                </div>
                <div className="profile3-right-section">
                <div className="profile3-user-info">
                    <div className="profile3-user-info-name">{usr.FirstName} 
                      &nbsp;{usr.Nickname?.length>0 &&
                      <span style={{color: '#f95d2d'}}>({usr.Nickname})</span>
                      
                      }
                      
                       </div>
                    <div className="profile3-user-info-lastname">{usr.LastName} </div>
                    <div className="profile3-user-details">
                    <div>Age:{usr.Age}</div> 
                    <div>Height:{usr.Height}cm</div> 
                    <div>Weight:{usr.Weight}kg</div> 
                    <div>
                    {usr.LeftRight==1 &&
                        <>{'Right Handed/Footed'}</>
                        }
                        {usr.LeftRight==2 &&
                        <>{'Left Handed/Footed'}</>
                        }
                        {usr.LeftRight==3 &&
                        <>{'Both Left & Right Handed/Footed'}</>
                        }

                      
                    </div>
                    {usr.NumOfFriends>0 &&
                    <div style={{marginTop:'20px'}}>
                    <button onClick={()=>navigate('/friendslist/'+usr.id)} className=" btn profile3-info-buttons"> <i className="bi bi-person-check-fill"></i> {usr.NumOfFriends} Friends</button>
                    </div> 
                    }
                    </div>
                    <div className="d-flex gap-2 mt-4">
                    {uid!=userID &&
                    <>                    
                    {friendShip.length==0 && 
                    <button disabled={!addFriendBtnVis} onClick={()=>addFriend(usr.id)} className=" btn profile3-info-buttons"> <i className="bi bi-plus"></i> ADD</button>
                    }

                    {friendShip.length>0 && 
                    <>
                        {friendShip[0].Status==0 &&
                        <div className="profile-add-user">
                          
                          <button disabled={true} onClick={()=>aproveFriendship(friendShip[0].UUID)}><i className="bi bi-person-plus-fill" 
                              style={{color:friendShip[0].SenderIsMe==0 ? 'gray' : 'gray', fontSize:'25px'}}></i>
                          </button>                          
                          
                        </div>
                        }
                      </>
                      }

                    <button onClick={()=>blockUser()} className=" btn profile3-info-buttons"><i className="bi bi-ban"></i> BLOCK</button>
                    <button onClick={()=>createRoom()} className=" btn profile3-info-buttons"> <i className="bi bi-chat-left"></i> CHAT</button>
                    </>
                    }
                    </div>

                </div>
                <div className="d-flex  gap-3 align-items-center w-100 mt-5">
                    <div className="profile-nationality">
                    <Flag code={ countries.filter(item=>item.CountryID==usr.CountryID)[0]?.CountryCode} height="80" width="80"/> 
                    </div>
                    <div className="profile-rank">#{usr.FavouriteNumber}</div>
                </div>
                <div className="profile-previous-teams mt-4">
                    <span style={{fontSize:'10px', color:'white'}}>Previously on</span>
                    <span style={{fontSize: '14px', color: '#f95d2d'}}>{usr.PreviousTeams}</span>
                </div>
            </div>      
        </section>
        <div>
        <div className="profile3-bio">
        {usr.PersonalInfo}
        </div>

        <section>
          <div className="profile-challenges d-flex ">
              <div className="container">  
                <div className="row mb-3"> 

                <Carousel 
                responsive={responsive}
                itemClass="carousel-item-padding-40-px"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                containerClass="carousel-container"
              >
                {storiesList}
              </Carousel>


                </div> 
              </div>
            </div>


            <div className="container mb-5">
                    {photosList}
            </div>        
        </section>


      </div>
        </>
        }
    </>

    </div>
  )
}
