import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import Logo from '../../css/images/logo-high3.png'
import bgImage from '../../css/images/forgot4.jpg'
import axios from 'axios'

export default function ForgotPassword() {
  const [success,setSuccess]=useState(0)
    const [photo,setPhoto]=useState('')
    const [Email,setEmail]=useState('')
    const navigate=useNavigate ()

    useEffect(()=>{
      loadPhoto()
    },[])
  
    const loadPhoto=()=>{
      
  
      const uuid=localStorage.getItem('SportUUID')
      const t=123//Date.now()
      setPhoto(process.env.REACT_APP_API_SERVER+'sports/photo/'+uuid+'/'+t)
    }
  
    const sendLink=(e)=>{
      e.preventDefault()
      axios.post(process.env.REACT_APP_API_SERVER+'sendpwdlink',{Email}).then(res=>{
        if (res.data.err==0){
            setSuccess(1)
        }else{
            setSuccess(2)
        }
      })
    }

  return (
    <div className="container container-mobile">
      <div className="div-wrap">
            <div className="box-image" style={{backgroundImage:'url('+photo+')'}}>
              <div className="logo"><img src={Logo} alt=""/></div>
            </div>
          <div className="form-div">
            <div className="mb-4">
                <span className="greet">Hi,</span>
                <span className="reset-span">Please type your email to get your reset link</span>
            </div>
            <form onSubmit={sendLink}>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInputResetEmail"
                  placeholder="name@example.com"
                  required
                  maxLength={64}
                  value={Email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <label for="floatingInputResetEmail">Email address</label>
              </div>
              
              <button type="submit" className="btn orange mt-3">Send Password Reset Link</button>
              <br/>
              <button type="submit" onClick={()=>navigate('/login')} className="btn orange mt-3" style={{backgroundColor:'#f0f0f0',color:'gray',borderColor:'gray'}}>Cancel</button>
            
              
              {success==1 &&
                <>
                <br/><br/>
                <div className="alert alert-success" role="alert">
                Please check your Email for the password recovery link.
                </div>                  
                </>
                }

                {success==2 &&
                <>
                <br/><br/>
                <div className="alert alert-danger" role="alert">
                An error has occured. Please try again.
                </div>                  
                </>
                }            
            
            </form>				
          </div> 
        </div>
      </div>

  )
}
