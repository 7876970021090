import React,{useState,useEffect,useContext,createContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

export default function AccountActivation() {
    const [success,setSuccess]=useState(0)
    const {uuid}=useParams()


    const activate=()=>{
        axios.get(process.env.REACT_APP_API_SERVER+'activateaccount/'+uuid).then(res=>{
            if (res.data.err==0){
                setSuccess(1)
            }else{
                setSuccess(2)
            }
        })
    }

    return (
        <div className="container">
                <div className="alert alert-warning" role="alert">
                    Account Activation
                </div>  

                <p align="center">
                <button onClick={activate} type="button" className="btn btn-success">Click Here To Activate Your Account</button>
                </p>

                {success==1 &&
                <div className="alert alert-success" role="alert">
                Conratulations! Your account is active. Go back to app and use your credentials (email, password) to login. 
                </div>                  
                
                }

                {success==2 &&
                <div className="alert alert-danger" role="alert">
                An error has occured. Please try again.
                </div>                  
                
                }

        </div>
    )
}
