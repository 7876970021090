import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import {UserContext} from '../../context/UserContext'


import Footer from '../Components/Footer'
import axios from 'axios'

export default function Privacy() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const {sportID,langID}= useContext(UserContext)
    const [sports,setSports]=useState([])
    const [countries,setCountries]=useState([])
    const [positions,setPositions]=useState([])
    const [usr,setUsr]=useState({})
    const [p1,setP1]=useState('')
    const [p2,setP2]=useState('')
    const [profileSaved,setProfileSaved]=useState(0)
    const navigate=useNavigate()

    useEffect(()=>{
        loadData()


        window.scrollTo({ top: 0, behavior: 'smooth' })
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'userprofile/'+userID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{

           setUsr(res.data.results)
            console.log(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            //console.log(err)
        })          
    }



      




    const save=(e)=>{
        e.preventDefault()
        const obj={Password:p1}
        obj.id=userID

        axios.post(process.env.REACT_APP_API_SERVER+'changepassword',obj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })        
        .then(res=>{
    
          if (res.data.err==0){
            
            setProfileSaved(1)
            navigate('/menu')
          }else{
            setProfileSaved(2)
          }

          //setTimeout(setProfileSaved(0),2000)
    
        }).catch(err=>{
          alert("An error has occured, please try again")
        })         

    }

  return (
    <>
      <header>
      <div className="upper-section upper-dark">
        <div className="d-flex align-items-center">
            {/* <div className="upper-arrow">
                <button><img src="images/left-arrow.png"/></button>
              </div> */}
          <div className="upper-texts">
            <div className="text1-w">personal details</div>
            <div className="text2-g">{firstName} {lastName}</div>
          </div>
        </div>
        <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')'}}></div>
      </div>    
  </header>

  <section className="pb-4 section-margin">
  <div className="container container-mobile">
      <div className="div-wrap">
        
        {isLoading &&
          <div className="alert alert-warning" role="alert">
          Loading, please wait...
          </div>          
        }
        
        {!isLoading &&
        <div className="form-div2">
            <div className="alert alert-secondary" role="alert">
            <b>Privacy Settings</b>
            </div>

        </div>
        }
      </div>
    </div>  


  </section>
  </>
  )
}
