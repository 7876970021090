import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import {UserContext} from '../../context/UserContext'
import challengeButton2 from  '../../css/images/challenge-button2.png'
import basket1 from  '../../css/images/basket1.jpg'
import basket2 from  '../../css/images/basket2.jpg'

import otthLogo from '../../css/images/logo-b-orange.jpg'
import flame from '../../css/images/flame.png'
import thunder from '../../css/images/thunder-black.png'
import achievement from '../../css/images/achievement.png'



export default function Challenges() {
  const {userID,setUserID }= useContext(UserContext)
  const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
  const [articles,setArticles]=useState([])
  const [articlesFlt,setArticlesFlt]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [challenge,setChallenge]=useState({})
  const [usrChallenges,setUsrChallenges]=useState([])
  const { loadUnreadNotifications}=useContext(UserContext)
  const vidEl=useRef()
  const navigate=useNavigate()

  useEffect(()=>{
    //window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(1)
    loadData()
    loadUserData()

    // // Add event listener when the component mounts
    // window.addEventListener('scroll', handleScroll);
    // // Remove event listener when the component unmounts
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };

  },[])

  const loadData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'chllengeslist/'+sportID+'/'+langID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
       //console.log(res.data) 
       setArticles(res.data)
       setArticlesFlt(res.data)
      setIsLoading(false)
      loadUnreadNotifications(userID)
      //console.log(res.data)
      
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })          
  }


  const loadUserData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'challengelistbyuser/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      
      setUsrChallenges(res.data.results)
      setIsLoading(false)
      
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })          
  }


  const getChallenge=(item,taken)=>{
   // console.log(item)
    if (taken==0){
      // const present=moment(Date.now())
      // const startChallenge=moment(item.DateTo)

      const present=moment(Date.now())
      const startChallenge=moment(item.DateTo+' 23:59:59')
      //const dif=startChallenge.diff(present, 'hours')      
      const dif=startChallenge.diff(present, 'hours')
      item.dif=dif
      //console.log(item.UUID)
      setChallenge(item)
      navigate('/challengepost/'+item.UUID+'/'+item.LangID)
    }else{
      navigate('/challengepost/'+item.UUID+'/'+item.LangID)
    }

  }

  const articlesList=articles.map((item,index)=>{
    var articlePhoto=''
    var author=''
    var authorImg=''

      articlePhoto=`${process.env.REACT_APP_API_SERVER}/admin/files/${item.UUID}/123`
      author='123High'
      authorImg=otthLogo

    const present=moment(Date.now())
    const startChallenge=moment(item.DateTo+' 23:59:59')
    const dif=startChallenge.diff(present, 'hours')

    var challengeTaken=0

    // console.log(item.id)
    //console.log(usrChallenges)
    if (usrChallenges.filter(xitem=>xitem.ChallengeID==item.ChallengID).length>0){
      challengeTaken=1
    }

    
    if (dif>0){
      return(

        <>
      
       <div key={`chlng${index}`} className="challenge-posts-item" onClick={()=>getChallenge(item,challengeTaken)} >
          <div className="challenge-multi-post-image" style={{backgroundImage:'url('+articlePhoto+')'}} >
              <div className="challenge-post-points">
                  <div className="challenge-post-title">{item.Title}</div>
                  <div className="d-flex">
                      <div className="d-flex gap-2 align-items-center">{dif} - hours <i className="bi bi-clock" style={{fontSize:'14px'}}></i></div>
                      {challengeTaken==0 &&
                      <div className="d-flex align-items-center"><button onClick={()=>getChallenge(item,challengeTaken)} ><i className="bi bi-play-fill" style={{color:'#f0f0f0', fontSize:'28px'}}></i></button></div>
                      }
                      {challengeTaken==1 &&
                      <div className="d-flex align-items-center"><button  onClick={()=>getChallenge(item,challengeTaken)}><i className="bi bi-play-fill" style={{color:'#f95d2d', fontSize:'28px'}}></i></button></div>
                      }


                  </div>
              </div>
          </div>

      
      </div>
        <hr/>
      </>
)
    }

  })


  return (
    <>

<div className="container container-mobile bg-grey" style={{paddingBottom: '40px'}}>
      <header>
          <div className="upper-section bg-white">
            <div className="d-flex align-items-center">

              <div className="upper-texts">
                <div className="text1-w"><span style={{color:'#f95d2d', fontWeight:'bold'}}>Active</span></div>
                <div className="text2-g fw-bold">Challenges</div>
              </div>
            </div>
            <div className="upper-photo" style={{backgroundImage:'url('+`${challengeButton2}`+')'}}><span style={{color:'f95d2d', fontSize:'18px', fontWeight: 'bold'}}>{articles.length}</span></div>
          </div>    
      </header>

      {isLoading &&
           <>
           <br/><br/><br/><br/><br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
            }
     
      <div className="modal fade" id="Modal1" tabindex="-1" aria-labelledby="Modal1Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content modal-specs">

            <div className="modal-body">
              <div className="text-center">
                <div className="fw-bold">{challenge.Title}</div>
                <div className="modal-text">You have accept the challenge. Get ready, you have {challenge.dif} hours</div> 
              </div>
              
            </div>
            <div className="modal-footer">
              <div className="row w-100 m-0">
                <div className="col p-0"><button type="button" className="w-100 fw-bold" data-bs-dismiss="modal">Close</button></div>
                {/* <div className="col p-0"><button type="button" data-bs-dismiss="modal" onClick={()=>alert('/challengepost/'+challenge.UUID+'/'+challenge.LangID)} className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>OK</button></div> */}
                <div className="col p-0"><button type="button" data-bs-dismiss="modal" onClick={()=>navigate('/challengepost/'+challenge.UUID+'/'+challenge.LangID)} className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>OK</button></div>
              </div>    
            </div>
          </div>
        </div>
      </div>
   
            {!isLoading &&
              <section className="section-margin">
              {articlesList}      

            </section>
            }

    </div>


    
    
    </>
  )
}
