import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import Logo from '../../css/images/logo-high3.png'
import {UserContext} from '../../context/UserContext'
//import bgImage from '../../css/images/Signup3.jpg'
import bgImage from '../../css/images/MainBg.png'
import io from 'socket.io-client'
import axios from 'axios'

export default function Login(props) {
  const [success,setSuccess]=useState(0)
  const [msg,setMsg]=useState('')
  const [isLoading,setIsLoading]=useState(true)
  //const {userName,setUserName}=useContext(UserContext)
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userID,setUserID }= useContext(UserContext)
  const {usrPhoto,setUsrPhoto}= useContext(UserContext)
  const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
  const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
  const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
  const {usersWhoBlockedMe,setUsersWhoBlockedMe}= useContext(UserContext)
  const {loadUsersWhoBlockedMe}=useContext(UserContext)
  const {socket,setSocket}=useContext(UserContext)
  const {loadUnreadMessages}=useContext(UserContext)
  const { loadUnreadNotifications}=useContext(UserContext)
  const {generalNotifications,setGeneralNotifications}=useContext(UserContext)


  

  const navigate=useNavigate ()
  const [photo,setPhoto]=useState('')
  const [loginObj,setLoginObj]=useState({
    Email:localStorage.getItem('Email') ? localStorage.getItem('Email')  : '',
    Password:localStorage.getItem('Password') ? localStorage.getItem('Password')  : ''
  })



  useEffect(()=>{
    
    sessionStorage.setItem('scrollY',0)
    if (localStorage.getItem('SportUUID')){
      loadPhoto()
    }else{
      setPhoto(bgImage)
    }

    if (localStorage.getItem('Password')){
      //setIsLoading(false)
      sendLogin()
    }else{
      setIsLoading(false)
    }
  },[])

  const loadPhoto=()=>{
    

    const uuid=localStorage.getItem('SportUUID')
    const t=123//Date.now()
    setPhoto(process.env.REACT_APP_API_SERVER+'sports/photo/'+uuid+'/'+t)
  }


  const password_show_hide=()=> {
    var x = document.getElementById("floatingPassword");
    var show_eye = document.getElementById("show_eye");
    var hide_eye = document.getElementById("hide_eye");
    hide_eye.classList.remove("d-none");
    if (x.type === "password") {
      x.type = "text";
      show_eye.style.display = "none";
      hide_eye.style.display = "block";
    } else {
      x.type = "password";
      show_eye.style.display = "block";
      hide_eye.style.display = "none";
    }

}

const login=(e)=>{
  e.preventDefault()
  

  sendLogin()
}

const sendLogin=()=>{
  axios.post(process.env.REACT_APP_API_SERVER+'login',loginObj)
  .then(async res=>{
      if (res.data.err==0){
          sessionStorage.setItem('token', res.data.token)

            setApiToken(res.data.token)
            setIsAuth(true)
            setUserID(res.data.uid)
            setUsrPhoto(loadImage(res.data.uid))
            setFirstName(res.data.FirstName)
            setLastName(res.data.LastName)
            setSportID(res.data.SportID)
            setLangID(res.data.LangID)
            setProfileIsPublic(res.data.ProfileIsPublic)
            localStorage.setItem('Email', loginObj.Email)
            localStorage.setItem('Password', loginObj.Password)
            sessionStorage.setItem('WelcomeText',res.data.WelcomeText)
            //console.log(res.data.SportUUID)
            localStorage.setItem('SportUUID', res.data.SportUUID);
            loadUnreadMessages(res.data.uid)
            loadUnreadNotifications(res.data.uid)
            socket.emit('user-connected',res.data.uid)
            setSuccess(1)


          // if( navigator.userAgent.includes ('wv')){
          //   if (res.data.ProfileIsUpdated==1){
          //     window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(1)
          //   }else{
          //     window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(2)
          //   }
          // }else{
            if (res.data.ProfileIsUpdated==1){
              navigate('/home')
            }else{
              navigate('/menume')
            }
          //}

          
      }else{
        sessionStorage.setItem('token', 'xxxx')

          localStorage.removeItem("Password")
          setIsAuth(false)
          setSuccess(2)
          setMsg(res.data.msg)
          setIsLoading(false)
          
      }

    }).catch(err=>{

      sessionStorage.setItem('token', 'xxxx')

      localStorage.removeItem("Password")
      setIsAuth(false)
      setSuccess(2)
      setIsLoading(false)
  })   
}


const loadImage=(id)=>{
  const d=Date.now()
  //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
  return(`${process.env.REACT_APP_API_SERVER}files/${id}/${d}`)
}

  return (
    <>
    {!isLoading &&
    <div className="container container-mobile">
        {photo.length>0 &&
            <div className="div-wrap">
                  <div className="box-image" style={{backgroundImage:'url('+photo+')'}}>
                    <div className="logo"><img src={Logo} alt=""/></div>
                  </div>
                <div className="form-div">
                  <div className="form-buttons w-100 justify-content-space-between mb-3 border-grey">
                    <button type="button" className="btn w-100 btn-orange">Login</button>
                    <button type="button" className="btn w-100 btn-grey" onClick={()=>navigate('/signup')}>Sign Up</button>
                  </div>
                  <form onSubmit={login}>
              
                  {props.activate==1 &&
                  
                    <div className="alert alert-danger" role="alert">
                      <b>Please check your email to activate your account.</b>
                    </div>            
                  
                  }
                  
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        required
                        value={loginObj.Email}
                        onChange={(e)=>setLoginObj({...loginObj,Email:e.target.value})}
                      />
                      <label for="floatingInput">Email address</label>
                    </div>
                    <div className="input-group d-flex mb-3">
                      <div className="form-floating">
                        <input
                        type="password"
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        required
                        value={loginObj.Password}
                        onChange={(e)=>setLoginObj({...loginObj,Password:e.target.value})}
                        />
                        <label for="floatingPassword">Password</label>	
                      </div>	
                      <div className="input-group-append d-flex align-items-center password-box">
                        <span className="input-group-text password-eye" onClick={password_show_hide}>
                          <i className="bi bi-eye" id="show_eye"></i>
                          <i className="bi bi-eye-slash d-none" id="hide_eye"></i>
                        </span>
                      </div>
                    </div>
                    <div className="forgot-password-link"><span onClick={()=>navigate('/forgotpwd')}>Forgot password?</span></div>	
                    <button type="submit" className="btn orange mt-3">Login</button>

                    {success==2 &&
                      <>
                      <br/><br/>
                      <div className="alert alert-danger" role="alert">
                      {msg}
                      </div>                  
                      </>
                      }   
                  </form>				
                </div> 
              </div>
            }
    </div>
    }

    {isLoading &&
           <>
           <br/><br/><br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>  
    }
  </>
    

    


  )
}
