import React,{useState,useEffect,useContext,createContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import "./css/style.css"

export default function PasswordRecovery() {
    const [success,setSuccess]=useState(0)
    const [usr,setUsr]=useState({})
    const [err,setErr]=useState(false)
    const [errMsg,setErrMsg]=useState('')
    const {uuid}=useParams()
    const [regObj,setRegObj]=useState({
        uuid,
        Password:'',
        OtherPassword:''
    })
    

    useEffect(()=>{
        loadUsr()
    },[])

    const password_show_hide=()=> {
        var x = document.getElementById("floatingPassword");
        var show_eye = document.getElementById("show_eye");
        var hide_eye = document.getElementById("hide_eye");
        hide_eye.classList.remove("d-none");
        if (x.type === "password") {
          x.type = "text";
          show_eye.style.display = "none";
          hide_eye.style.display = "block";
        } else {
          x.type = "password";
          show_eye.style.display = "block";
          hide_eye.style.display = "none";
        }
    
    }
    
    
    function password_show_hide2() {
      var x = document.getElementById("floatingPassword2");
      var show_eye = document.getElementById("show_eye2");
      var hide_eye = document.getElementById("hide_eye2");
      hide_eye.classList.remove("d-none");
      if (x.type === "password") {
        x.type = "text";
        show_eye.style.display = "none";
        hide_eye.style.display = "block";
      } else {
        x.type = "password";
        show_eye.style.display = "block";
        hide_eye.style.display = "none";
      }
    }
    


    const loadUsr=()=>{
        axios.get(process.env.REACT_APP_API_SERVER+'/sendpwdlink/userdetails/'+uuid).then(res=>{
            if (res.data.err==0){
                setUsr(res.data.results)
                setErr(false)
            }else{  
                setErr(true)
            }
        })
    }

    const sendPwd=(e)=>{
        e.preventDefault()
        if (regObj.Password==regObj.OtherPassword){
            axios.post(process.env.REACT_APP_API_SERVER+'/sendpwdlink/changepassword',regObj).then(res=>{
                if (res.data.err==0){
                    setSuccess(1)
                }else{
                    setSuccess(2)
                    setErrMsg('An error has occured')
                }
                
            })
        }else{
            setSuccess(2)
            setErrMsg('Error. Passwords do not match')
        }
    }

    return (
        <div className="container">
                
                <div className="alert alert-warning" role="alert">
                    Password Recovery
                </div>  

                {err &&
                <div className="alert alert-danger" role="alert">
                    Error
                </div>                      
                }



                {!err &&
                <>
                    <div className="alert alert-success" role="alert">
                    Welcome {usr.FirstName} {usr.LastName}
                    </div> 

                    {success==2 &&
                        <div className="alert alert-danger" role="alert">
                        {errMsg}
                        </div>                  
                        
                    }

                    {success==1 &&
                        <div className="alert alert-success" role="alert">
                        New password set!
                        </div>                  
                        
                    }

                    <form onSubmit={sendPwd}>
                        <div className="input-group d-flex mb-3">
                        <div className="form-floating">
                        <input
                            minLength={6}
                            maxLength={10}
                            type="password"
                            className="form-control"
                            id="floatingPassword"
                            placeholder="Password"
                            required
                            value={regObj.Password}
                            onChange={(e)=>setRegObj({...regObj,Password:e.target.value})}
                        />
                        <label for="floatingPassword">Password</label>
                        </div>
                        <div
                        className="input-group-append d-flex align-items-center password-box"
                        >
                        <span
                            className="input-group-text password-eye"
                            onClick={password_show_hide}
                        >
                            <i className="bi bi-eye" id="show_eye"></i>
                            <i className="bi bi-eye-slash d-none" id="hide_eye"></i>
                        </span>
                        </div>
                    </div>
                    <div className="input-group d-flex mb-3">
                        <div className="form-floating">
                        <input
                            maxLength={10}
                            type="password"
                            className="form-control"
                            id="floatingPassword2"
                            placeholder="Repeat Password"
                            required
                            value={regObj.OtherPassword}
                            onChange={(e)=>setRegObj({...regObj,OtherPassword:e.target.value})}
                        />
                        <label for="floatingPassword2">Repeat Password</label>
                        </div>
                        <div
                        className="input-group-append d-flex align-items-center password-box"
                        >
                        <span
                            className="input-group-text password-eye"
                            onClick={password_show_hide2}
                        >
                            <i className="bi bi-eye" id="show_eye2"></i>
                            <i className="bi bi-eye-slash d-none" id="hide_eye2"></i>
                        </span>
                        </div>
                    </div>    
                    <p align="center">
                    <button type="submit" class="btn btn-dark">Change Password</button> 
                    </p>                   
                    </form>   
                </>
                }
        </div>
    )
}
