import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import timer3 from  '../../css/images/timer3.png'
import leftArrow from  '../../css/images/left-arrow.png'
import flame from  '../../css/images/flame.png'
import thunder from  '../../css/images/thunder.png'
import achievement from  '../../css/images/achievement.png'
import fire2Stop from  '../../css/images/fire2-stop.png'
import thunder3Stop from  '../../css/images/thunder3-stop.png'
import achievement2Stop from '../../css/images/achievement2-stop.png'

import fire2 from '../../css/images/fire2.gif'
import thunder3 from '../../css/images/thunder3.gif'
import achievement2 from '../../css/images/achievement2.gif'
import flamesfull2 from '../../css/images/flamesfull2.gif'
import thunderfull from '../../css/images/thunderfull.gif'
import man2 from '../../css/images/man2.jpg'
import danger from '../../css/images/danger.png'

import {UserContext} from '../../context/UserContext'
import axios from 'axios'

export default function PostProfileStory() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
    const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
    const [articles,setArticles]=useState([])
    const [articlesFlt,setArticlesFlt]=useState([])
    const [isLoading,setIsLoading]=useState(true)
    const [isLoadingReactions,setIsLoadingReactions]=useState(true)
    const [isReacting,setIsReacting]=useState(false)
    const [challenge,setChallenge]=useState({})
    const [reactions,setReactions]=useState([])
    const [reportDetail,setReportDetail]=useState('')
    const [reportSent,setReportSent]=useState(false)
    const [hasResults,setHasResults]=useState(false)
    const [success,setSuccess]=useState(false)
    const fileInputRef = useRef(null)
    const vidEl=useRef()
  
  
   

    const {id}=useParams()
    const {langid}=useParams()
    const {uid}=useParams()
    const navigate=useNavigate()


  useEffect(()=>{
    loadData()
    
  },[])

  const loadReactions=()=>{
    setIsLoadingReactions(true)
    axios.get(process.env.REACT_APP_API_SERVER+'postreactions/'+id,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      setReactions(res.data)
      setIsLoadingReactions(false)
      setIsReacting(false)

      
    }).catch(err=>{
      console.log(err)
      setIsLoadingReactions(false)
    })    
  }

  const loadData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'postprofilestory/'+id+'/'+langid+'/'+uid,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      //console.log(res.data)
      if (res.data.err==0){
      //  console.log(res.data.results)
       // if (res.data.results.length>0){
          setChallenge(res.data.results) 
          loadReactions()
          setHasResults(true)
        // }else{
        //   setHasResults(false)
        // }

       //console.log(res.data.results)
      }else{
        setHasResults(false)
        //alert('An error has occured')
      }
      setIsLoading(false)


      
    }).catch(err=>{
      console.log(err)
        setIsLoading(false)
    })
  }


  const sendReaction=(r)=>{
    setIsReacting(true)
    const obj={
      ChallengeID:id,
      ChUserID:uid,
      ReactUserID:userID,
      Rank:r,
      LangID:langID,
      ReactFullName:firstName+" "+lastName
    }
    //console.log(obj)
    axios.post(process.env.REACT_APP_API_SERVER+'postreactions',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

      loadReactions()
      
      
    }).catch(err=>{
      console.log(err)

    })


   // 
  }


  const toggleActive1=()=>{
    document.getElementById("fire-animation").src=fire2;
    setTimeout(function(){ sendReaction(3);document.getElementById("fire-animation").src=fire2Stop; }, 1500);
  }

  const toggleActive2=()=>{
    document.getElementById("thunder-animation").src=thunder3;
    setTimeout(function(){ sendReaction(2);document.getElementById("thunder-animation").src=thunder3Stop; }, 1500);
  }

  const toggleActive3=()=>{
    document.getElementById("achievement-animation").src=achievement2;
    setTimeout(function(){ sendReaction(1);document.getElementById("achievement-animation").src=achievement2Stop; }, 1500);
  }


  const toggleActive4=()=>{
    var element = document.getElementById("fire-full");
    element.classList.remove("hide");
    setTimeout(function(){ sendReaction(3);element.classList.add("hide") }, 2500);
    //setTimeout(function(){ sendReaction(3);document.getElementById("fire-animation").src=fire2Stop; }, 1500);
  }
  const toggleActive5=()=>{
    var element = document.getElementById("thunder-full");
    element.classList.remove("hide");
    setTimeout(function(){ sendReaction(2);element.classList.add("hide") }, 2200);
    //setTimeout(function(){ sendReaction(2);document.getElementById("thunder-animation").src=thunder3Stop; }, 1500);
  }
  const toggleActive6=()=>{
    var element = document.getElementById("award-full");
    element.classList.remove("hide");
    setTimeout(function(){ sendReaction(1);element.classList.add("hide") }, 2000);
    //setTimeout(function(){ sendReaction(1);document.getElementById("achievement-animation").src=achievement2Stop; }, 1500);
  }


  const showModal=()=>{
    setReportSent(false)
    setReportDetail('')
    setSuccess(false)

  }

  const sendReport=(e)=>{
    e.preventDefault()
    
    const obj={
        reportDetail,
        contentType:2,
        UUID:id,
        UsrUUID:uid,
        userID
    }

   // console.log(obj)
    axios.post(process.env.REACT_APP_API_SERVER+'report',obj,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{

        
        setReportSent(true)
        setSuccess(true)

  
        
      }).catch(err=>{
        console.log(err)
  
      })
}


  return (
    <>
  {!isLoading &&
  <div className="container container-mobile bg-grey">
      <header>
          <div className="upper-section upper-dark">
            <div className="d-flex align-items-center">
              <div className="upper-arrow">
                <button onClick={()=>navigate(-1)} ><img src={leftArrow}/></button>
              </div>
              <div className="upper-texts">
                <div className="text1-w">{challenge.FirstName} "{challenge.Nickname}" </div>
                <div className="text2-g2">{challenge.LastName}</div>
              </div>
              {/* {userID!=uid &&
              <div style={{marginLeft:20}}><img width="40" src={danger} alt="danger" data-bs-toggle='modal' onClick={()=>{showModal()}} data-bs-target={`#Modal${id}`}/>  
            </div> 
              }                 */}
            </div>

            <div onClick={()=>navigate('/profile/'+uid)} className="upper-photo" style={{backgroundImage: 'url('+`${process.env.REACT_APP_API_SERVER}files/${uid}/123`+')'}}></div>
          </div>    
      </header>

      
        <section className="section-margin">
        {hasResults &&      
        <div className="rounded-video">
              {!isLoading &&
               <>
                {challenge.FileID.length>0 &&
                <video className="challenge-post-video show" controls width="320" poster={process.env.REACT_APP_VIDEOSERVER_API+''+challenge.FileID+'.jpg'}>
                
                    <source 
                      src={`${process.env.REACT_APP_VIDEO_BASE_URL}${challenge.FileID}.smil/playlist.m3u8#t0.01`} 
                      type="video/mp4" preload="metadata"/>
                    Your browser does not support the video tag.
                </video>
                }

                {challenge.FileID.length==0 &&
               <>
               <img src={process.env.REACT_APP_API_SERVER+'postfiles/'+challenge.UUID+'/123'}class="challenge-post-photo show" alt="photo" />

                </>
                }                
              </>            
              }
              {!isLoading &&


              <>
              <div className="challenge-press-points">
                <div>
                  <button disabled={isReacting} onClick={()=>toggleActive4()} className="challenge-press-button" id="challenge-button4"><img src={fire2} id="f-animation"  alt="fire icon"/></button>
                  {reactions.filter(item=>item.RankType==3 && item.ChUserUUID==uid).length>0 ? reactions.filter(item=>item.RankType==3 && item.ChUserUUID==uid)[0].v : 0}
                </div>
                <div>
                    <button disabled={isReacting}onClick={()=>toggleActive5()} className="challenge-press-button" id="challenge-button2"><img src={thunder3} id="thunder-animation"   alt="thunder icon"/></button>
                    {reactions.filter(item=>item.RankType==2 && item.ChUserUUID==uid).length>0 ? reactions.filter(item=>item.RankType==2 && item.ChUserUUID==uid)[0].v : 0}  
                </div>
                <div>
                    <button disabled={isReacting}  onClick={()=>toggleActive6()} className="challenge-press-button" id="challenge-button3"><img src={achievement2} id="achievement-animation"  alt="achievement icon"/></button>
                    {reactions.filter(item=>item.RankType==1 && item.ChUserUUID==uid).length>0 ? reactions.filter(item=>item.RankType==1 && item.ChUserUUID==uid)[0].v : 0}
                </div>
                {/* <!-- <div><button onClick="toggleActive1()" className="challenge-press-button" id="challenge-button1"><img src="images/fire2-stop.png" id="fire-animation" alt="flame icon"></button></div> --> */}
              </div>
              <div id="fire-full" className="full-animation hide">
                <img src={flamesfull2} alt="thunder-animated"/>
              </div>
              <div id="thunder-full" className="full-animation hide">
                <img src={thunderfull} alt="thunder-animated"/>
              </div>
              <div id="award-full" className="full-animation hide">
                {/* <!-- <img src="images/award.png" alt="thunder-animated" id="award"> --> */}
              </div>
              </>              
              }

            {isLoadingReactions &&
            <div style={{ display: 'flex',paddingLeft:'30px',height:'40px'}}></div>
            
            }               
          </div>
          }  

          {!hasResults &&
          <div className="alert alert-warning" role="alert" style={{marginLeft:20,marginRight:20}}>
          This content is not available right now
          </div>      
          
          }          
        </section>

    </div>
    }    
    
    <div className="modal fade" id={`Modal${id}`} tabindex="-1" aria-labelledby={`Modal${id}Label`} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content modal-specs">
            <form onSubmit={sendReport}>
            <div className="modal-body">
              <div className="text-center">
                <div className="fw-bold"></div>
                <div className="modal-text">Objectionable Content Report</div> 
              </div>
                    
                    <div className="form-floating mb-3">
                        <input
                        disabled={reportSent}
                        maxLength={250}
                        type="text"
                        className="form-control"
                        id="floatingInput2"
                        placeholder="Mobile phone"
                        required
                        value={reportDetail}
                        onChange={(e)=>setReportDetail(e.target.value)}
                        />
                        {!reportSent &&
                        <label for="floatingInput2">Please Explain</label>
                        }       

                        {reportSent &&
                        <label for="floatingInput2"><b>Report sent to administrator</b></label>
                        }       



                    </div>     

                 
  


            </div>
            <div className="modal-footer">
              <div className="row w-100 m-0">
                <div className="col p-0"><button type="button" className="w-100 fw-bold" data-bs-dismiss="modal">
                    {!reportSent ? 'Cancel' : 'Close'}
                </button></div>
                {!reportSent &&
                <div className="col p-0"><button type="submit" className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>Send Report</button></div>
                }
                {/* <div className="col p-0"><button type="button" data-bs-dismiss="modal" onClick={()=>navigate('/challengepost/'+challenge.UUID+'/'+challenge.LangID)} className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>OK</button></div> */}
              </div>    
            </div>
            </form> 
          </div>
          
        </div>
      </div>



     {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
      }
    </>
  )
}
