import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import burnBg from '../../css/images/burn-bg.png'
import burnBlackOrange from '../../css/images/burn-black-orange.png'
import man2 from '../../css/images/man2.jpg'
import man from '../../css/images/man.jpg'
import man3 from '../../css/images/man3.jpg'
import burnWhite from '../../css/images/burn-white.png'
import flame from '../../css/images/flame.png'
import woman from '../../css/images/woman.jpg'
import {UserContext} from '../../context/UserContext'
import leftArrow from  '../../css/images/left-arrow.png'
import axios from 'axios'
import moment from 'moment'

export default function FriendsList() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(true)
    const {sportID,langID}= useContext(UserContext)
    const [sports,setSports]=useState([])
    const [countries,setCountries]=useState([])
    const [positions,setPositions]=useState([])
    const [wallOfFame,setWallOfFame]=useState([])
    const [profileSaved,setProfileSaved]=useState(0)
    const [friends,setFriends]=useState([])
    const [usr,setUsr]=useState({})
    const {userUUID}=useParams()

    const navigate=useNavigate()

    useEffect(()=>{
        loadData()
        loadProfile()
    },[])


    const loadProfile=()=>{
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'userprofile/'+userUUID,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{

         setUsr(res.data.results)

         setIsLoading(false)
      }).catch(err=>{
          setIsLoading(false)
  
      })          
  }


    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'friends/userfriends/'+userUUID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{
         // console.log(res.data.friends)
          setFriends(res.data.friends.filter(item=>item.Status==1))
          setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            //console.log(err)
        }) 
    }

    const removeFriend=(sid,rid)=>{
      setIsLoading(true)
      const obj={
        SenderUUID:sid,
        ReceiverUUID:rid
      }
      axios.post(process.env.REACT_APP_API_SERVER+'friends/remove',obj,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{
  
        loadData()
        setIsLoading(false)
      }).catch(err=>{
          setIsLoading(false)
          //console.log(err)
      }) 
    }


    const friendsList=friends.map(item=>{
      console.log(item)
        const authorImg=item.SenderUUID!==userUUID ? `${process.env.REACT_APP_API_SERVER}files/${item.SenderUUID}/1234` : `${process.env.REACT_APP_API_SERVER}files/${item.ReceiverUUID}/1234`
        const UUID=item.SenderUUID!==userUUID ? item.SenderUUID : item.ReceiverUUID
        const FN=item.SenderUUID!==userUUID ? item.SenderFullName : item.ReceiverFullName
        return(
            <div className="chat-avatar-item">
            <div onClick={()=>navigate('/profile/'+UUID)} className="chat-user-photo" style={{backgroundImage:'url('+authorImg+')'}}></div>
            <div className="chat-header">
                <div className="chat-header-text">{FN}</div>
                <div className="chat-header-time">{moment(item.FDate).format('DD/MM/YYYY')} </div>
                {userUUID==userID && 
                <button onClick={()=>removeFriend(item.SenderUUID,item.ReceiverUUID)} className="btn btn-danger">Remove Friend</button>
                }
                <hr/>
            </div>      
        </div>
        )
    })


  return (
    <div class="container container-mobile bg-grey">
      <header>
    <div className="upper-section upper-dark">
    <div className="d-flex align-items-center">
      <div className="upper-arrow">
        <button onClick={()=>navigate(-1)}><img src={leftArrow}/></button>
      </div>
      <div className="upper-texts">
        <div className="text1-w"></div>
        {userUUID==userID && 
        <div className="text2-g">your friends</div>
        }

        {userUUID!=userID && 
        <div className="text2-g">{usr.FirstName} {usr.LastName} friends</div>
        }        
      </div>
    </div>

  </div>    
</header>

<section className="section-margin">


    {!isLoading &&
    <>
      {friendsList}

    </>
    }



      {isLoading &&
           <>
           <br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
     }

</section>

</div>


  )
}
