import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../../css/style.css'
import {UserContext} from '../../../context/UserContext'

export default function Header() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
    const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
    const {profileIsPublic,setProfileIsPublic}= useContext(UserContext)
    const [articles,setArticles]=useState([])
    const [reactions,setReactions]=useState([])
    const [postreactions,setPostReactions]=useState([])
    const [articlesFlt,setArticlesFlt]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [scrollY, setScrollY] = useState(0);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const [image, setImage] = useState(null);
    const [stories,setStories]=useState([])
    const {usersWhoBlockedMe,setUsersWhoBlockedMe}=useContext(UserContext)
    const {loadUsersWhoBlockedMe}=useContext(UserContext)
    const {newChallenge,setNewChallenge}=useContext(UserContext)
    const [friends,setFriends]=useState([])
    const [searchText,setSearchText]=useState('')
    const { loadUnreadNotifications}=useContext(UserContext)
    const navigate=useNavigate()
    const vidEl=useRef()
    const s=useRef()



    const search=()=>{
        if (searchText.length>0){
          //console.log(sportID)
          navigate('/searchresults/'+searchText.trim())
        }
        
      }
    
      const changeSearchText=(e)=>{
        setSearchText(e.target.value)
      }
    
      const handleKeyDown=(e)=>{
        if (e.key=='Enter'){
          search()
        }
      }
      

  return (
    <header>
        <div className="home-upper-section2 bg-white">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="upper-texts">
                        <div className="text2-g">Hi, {firstName}!</div>
                        <div className="home-upper-about">
                        <div className="home-upper-about-in">{sessionStorage.getItem('WelcomeText')}</div> 
                        </div>
                    </div>
                </div>
                <div onClick={()=>navigate('/profile/'+userID)} className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')',minWidth:'60px'}}></div>  
            </div>
            <div className="home-post">

                <form>
                    <div className="home-post-box">
                    <textarea type="text" placeholder="Search for friends" onKeyDown={(e)=>handleKeyDown(e)} value={searchText} onChange={(e)=>changeSearchText(e)}></textarea>  
                    <div className="home-post-box-buttons">
                        <button onClick={(e)=>{e.preventDefault();search()}} style={{backgroundColor: '#f95d2d', border:'1px solid #f95d2d'}}><i className="bi bi-search" style={{fontSize: '11px', fontWeight: 'bold', color:'white'}}></i></button>
                    </div>
                    </div>
                    <div className="home-post-box-buttons ms-3 align-items-center">
                    <button onClick={()=>navigate('/homepagepost')} style={{backgroundColor: 'black', border: '1px solid black'}}><i className="bi bi-plus" style={{color: '#fff'}}></i></button>
                    </div>
                </form>

            </div>
        </div>                
    </header>
  )
}
