import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import {UserContext} from '../../context/UserContext'


import Footer from '../Components/Footer'
import axios from 'axios'

export default function Account() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const {sportID,langID}= useContext(UserContext)
    const [sports,setSports]=useState([])
    const [countries,setCountries]=useState([])
    const [positions,setPositions]=useState([])
    const [usr,setUsr]=useState({})
    const [p1,setP1]=useState('')
    const [p2,setP2]=useState('')
    const [profileSaved,setProfileSaved]=useState(0)
    const navigate=useNavigate()

    useEffect(()=>{
        loadData()


        window.scrollTo({ top: 0, behavior: 'smooth' })
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'userprofile/'+userID,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{

           setUsr(res.data.results)
            console.log(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
            //console.log(err)
        })          
    }



      
    const deleteAccount=()=>{
      axios.get(process.env.REACT_APP_API_SERVER+'userprofile/deleteaccount/'+userID,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("token")
            }            
        })
      .then(res=>{
        if (res.data.err==0){
         // alert('Success')
          localStorage.removeItem("Password")
          sessionStorage.setItem("isAuth",0)
          navigate('/signup')          
        }else{
          alert('An error has occured')
        }

      }).catch(err=>{
        alert('An error has occured')
      })        
    }



    const save=(e)=>{
        e.preventDefault()
        const obj={Password:p1}
        obj.id=userID

        axios.post(process.env.REACT_APP_API_SERVER+'changepassword',obj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })        
        .then(res=>{
    
          if (res.data.err==0){
            
            setProfileSaved(1)
            navigate('/menu')
          }else{
            setProfileSaved(2)
          }

          //setTimeout(setProfileSaved(0),2000)
    
        }).catch(err=>{
          alert("An error has occured, please try again")
        })         

    }

  return (
    <>
      <header>
      <div className="upper-section upper-dark">
        <div className="d-flex align-items-center">
            {/* <div className="upper-arrow">
                <button><img src="images/left-arrow.png"/></button>
              </div> */}
          <div className="upper-texts">
            <div className="text1-w">personal details</div>
            <div className="text2-g2">{firstName} {lastName}</div>
          </div>
        </div>
        <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+usrPhoto+')'}}></div>
      </div>    
  </header>

  <section className="pb-4 section-margin">
  <div className="container container-mobile">
      <div className="div-wrap">
        
        {isLoading &&
          <div className="alert alert-warning" role="alert">
          Loading, please wait...
          </div>          
        }
        
        {!isLoading &&
        <div className="form-div2">

          <form onSubmit={save}>


            <div className="alert alert-secondary" role="alert">
            <b>Change your password</b>
            </div>
 


            <div className="form-floating mb-3">
              <input
                type="text"
                maxLength={32}
                className="form-control"
                id="floatingInput344"
                placeholder="Email"
                //required
                value={usr.Email}
                disabled
                onChange={(e)=>setUsr({...usr,Team:e.target.value})}                    
              />
              <label for="floatingInput344">Email</label>
            </div>


            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="floatingInput344"
                placeholder="Password"
                required
                minLength={6}
                maxLength={10}
                value={p1}
                onChange={(e)=>setP1(e.target.value)}                    
              />
              <label for="floatingInput344">Password</label>
            </div>


            <div className="form-floating mb-3">
              <input
                type="password"
                minLength={6}
                maxLength={10}
                className="form-control"
                id="floatingInput344"
                placeholder="Confirm Password"
                required
                value={p2}
                onChange={(e)=>setP2(e.target.value)}                    
              />
              <label for="floatingInput344">Confirm Password</label>
            </div>


            <button type="submit" disabled={!(p1.length>0 && p2.length>0 && p1==p2)} className="btn orange mt-3">Change Password</button>

            {profileSaved==1 &&
            <>
            <br/><br/>
            <div className="alert alert-success" role="alert">
            Profile Updated
            </div>
            </>          
            }   
            {profileSaved==2 &&
            <>
            <br/><br/>
            <div className="alert alert-danger" role="alert">
            An error has occured, please try again
            </div>
            </>          
            } 


          </form>
            <br/><br/>
          <div className="alert alert-secondary" role="alert">
            <b>Delete Account</b>
          </div>
            <p align="center">
            <button type="button" class="btn btn-danger" data-bs-toggle='modal'  data-bs-target="#Modal111">Delete Account</button>
            </p>

            <div className="modal fade" id="Modal111" tabindex="-1" aria-labelledby="Modal111Label" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content modal-specs">

                  <div className="modal-body">
                    <div className="text-center">
                      <div className="fw-bold">DELETE YOUR ACCOUNT</div>
                      <div className="modal-text">Are you sure?</div> 
                    </div>
                    
                  </div>
                  <div className="modal-footer">
                    <div className="row w-100 m-0">
                      <div className="col p-0"><button type="button" className="w-100 fw-bold" data-bs-dismiss="modal">Cancel</button></div>
                      {/* <div className="col p-0"><button type="button" data-bs-dismiss="modal" onClick={()=>alert('/challengepost/'+challenge.UUID+'/'+challenge.LangID)} className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>OK</button></div> */}
                      <div className="col p-0"><button type="button" data-bs-dismiss="modal" onClick={()=>deleteAccount()} className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>DELETE</button></div>
                    </div>    
                  </div>
                </div>
              </div>
            </div>



        </div>
        }
      </div>
    </div>  





  </section>
  </>
  )
}
