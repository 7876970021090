import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Navigate,useNavigate } from 'react-router-dom'
import burnBg from '../../css/images/burn-bg.png'
import burnBlackOrange from '../../css/images/burn-black-orange.png'
import man2 from '../../css/images/man2.jpg'
import man from '../../css/images/man.jpg'
import man3 from '../../css/images/man3.jpg'
import burnWhite from '../../css/images/burn-white.png'
import flame from '../../css/images/flame.png'
import woman from '../../css/images/woman.jpg'
import {UserContext} from '../../context/UserContext'



import Footer from '../Components/Footer'
import axios from 'axios'

export default function WallOfFame() {
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const {userID,setUserID }= useContext(UserContext)
  const {usrPhoto,setUsrPhoto}= useContext(UserContext)
  const {firstName,lastName}= useContext(UserContext)
  const [isLoading,setIsLoading]=useState(true)
  const {sportID,langID}= useContext(UserContext)
  const [sports,setSports]=useState([])
  const [countries,setCountries]=useState([])
  const [positions,setPositions]=useState([])
  const [wallOfFame,setWallOfFame]=useState([])
  const [friends,setFriends]=useState([])
  const [profileSaved,setProfileSaved]=useState(0)
  const [usersWhoBlockedMe,setUsersWhoBlockedMe]=useState([])
  const [addFriendBtnVis,setAddFriendBtnVis]=useState(true)
  const navigate=useNavigate()
  const {s}=useParams()

  useEffect(()=>{
    loadData()
  },[])

  const loadData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'searchresults/'+s+'/'+sportID+'/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      //console.log(langID)
      //console.log(res.data.results)
      //setWallOfFame(res.data.results)
       setWallOfFame(res.data.results.filter(item=>item.LangID==langID))
      //console.log(res.data.results.filter(item.LangID=langID))
       // console.log(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
      loadMyFriends()
      //  loadUsersWhoBlockedMe()
    }).catch(err=>{
        setIsLoading(false)
        //console.log(err)
    }) 
  }  

  const loadUsersWhoBlockedMe=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'block/blockeduser/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{

      setUsersWhoBlockedMe(res.data.friends)
      console.log(res.data.friends)
      setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)
        //console.log(err)
    }) 
  }

  const loadMyFriends=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'friends/userfriends/'+userID,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{


      //console.log(res.data)
      setFriends(res.data.friends)
       // console.log(res.data.results?.filter(item=>item.LangID==langID && item.SportID==sportID))
       setIsLoading(false)
       setAddFriendBtnVis(true)
    }).catch(err=>{
        setIsLoading(false)
        //console.log(err)
    }) 
  }  


  const loadImage=(id)=>{
    const d=Date.now()
    //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
    return(`${process.env.REACT_APP_API_SERVER}files/${id}/${d}`)
  }


  const addFriend=(ReceiverUUID,Email)=>{
    setIsLoading(true)
    setAddFriendBtnVis(false)
    const obj={
      SenderUUID:userID,
      ReceiverUUID,
      SenderName: firstName+' '+lastName,
      Email
    }
    axios.post(process.env.REACT_APP_API_SERVER+'friends/add',obj,{
      headers: {
        'Authorization': 'Bearer '+sessionStorage.getItem("token")
        }  
    }).then(res=>{
      if (res.data.err==0){
      //console.log(res.data)
        loadData()
      }else{
        alert('An error has occured')
      }
    })

  }

  const removeFriend=(ReceiverUUID)=>{
    const obj={
      SenderUUID:userID,
      ReceiverUUID
    }
    axios.post(process.env.REACT_APP_API_SERVER+'friends/remove',obj,{
      headers: {
        'Authorization': 'Bearer '+sessionStorage.getItem("token")
        }  
    }).then(res=>{
      if (res.data.err==0){
        loadData()
      }else{
        alert('An error has occured')
      }
    })

  }


  const wallOfFameList=wallOfFame.map((item,index)=>{
    if (usersWhoBlockedMe.filter(u=>u.UserWhoBlockedMeUUID==item.UUID).length==0){
    return(
      <div className="wof-avatar-item bg-orange" key={index}>
      <div className="wof-wrapper">
        <div className="wof-user">
          <div onClick={()=>navigate('/profile/'+item.UUID)}  className="wof-user-photo" style={{backgroundImage:'url('+loadImage(item.UUID)+')'}}></div>
          <div className="wof-header">
              <div className="wof-header-name">{item.FirstName} ({item.Nickname})</div>
              <div className="wof-header-team">{item.LastName}</div>
          </div> 
        </div>
        {item.UUID!=userID && 
        <>
          {friends.filter(f=>f.SenderUUID==item.UUID || f.ReceiverUUID==item.UUID).length==0 &&
            <div className="wof-user-photo" onClick={()=>addFriend(item.UUID,item.Email)}><span style={{color:'#f95d2d', fontSize:'30px', fontWeight: 'bold'}}>+</span></div>     
          }
          {/* {friends.filter(f=>f.SenderUUID==item.UUID || f.ReceiverUUID==item.UUID).length==1 &&
            <div className="wof-user-photo" onClick={()=>removeFriend(item.UUID)}><span style={{color:friends.filter(f=>f.SenderUUID==item.UUID || f.ReceiverUUID)[0].Status==1 ? 'green' :'gray', fontSize:'30px', fontWeight: 'bold'}}>V</span></div>     
          } */}
          </>
           
        }
        </div>
      <div className="wof-points"><img src={flame} alt="burn icon"/>{item.v} </div>
    </div>      
    )
    }
  })




  return (
<>

<div className="container container-mobile" style={{backgroundImage:'url('+`${burnBg}`+')',paddingBottom: '40px'}}>
      <header>
          <div className="upper-section bg-white">
            <div className="d-flex align-items-center">

              <div className="upper-texts">
                <div className="text1-w"><span style={{color:'#f95d2d', fontWeight:'bold'}}>Search for&nbsp;</span><span style={{color: 'grey'}}>{s}</span></div>
                <div className="text2-g fw-bold">Search Results</div>
              </div>
            </div>
            <div onClick={()=>navigate('/profile/'+userID)}  className="upper-photo" style={{backgroundImage:'url('+`${process.env.REACT_APP_API_SERVER}files/${userID}/1234`+')'}}><span style={{color:'white', fontSize:'18px', fontWeight: 'bold'}}>
              {/* 186 */}
              
              </span></div>
          </div>    
      </header>
 
        {isLoading &&
           <>
           <br/><br/><br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-secondary" role="alert">
              Loading, please wait...
            </div>  
            </div> 
            </>              
            }

      {!isLoading && wallOfFame.length>0 &&
        <section className="section-margin">
          {wallOfFameList}
        </section>
      }

        {(!isLoading) && wallOfFame.length==0 &&
           <>
           <br/><br/><br/><br/>
           <div className="home-posts-item">
              <div className="alert alert-warning" role="alert">
              no results found
            </div>  
            </div> 
            </>              
            }

    </div>


    
    
    
    </>
  )
}
