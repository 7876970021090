import React,{useState,useEffect,useContext,createContext,useRef} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../../css/style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {UserContext} from '../../../context/UserContext'
// import Footer from '../../Components/Footer'
import axios from 'axios'
import moment from 'moment'

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import cube3 from '../../../css/images/cube3.png'
import cube2 from '../../../css/images/cube2.png'
import foutline from '../../../css/images/f-outline.png'
import thunder7w from '../../../css/images/thunder7-w.png'
import medal3w from '../../../css//images/medal3-w.png'

import foutline2 from '../../../css/images/f-outline2.png'
import thunder5 from '../../../css/images/thunder5.png'
import achievement from '../../../css/images/achievement.png'

import octagon from '../../../css/images/octagon.png'

import flame from '../../../css/images/flame.png'

export default function WallPostItem(props) {
    const [articlePhoto,setArticlePhoto]=useState('')
    const [authorImg,setAuthorImg]=useState(new Image())
    const [author,setAuthor]=useState('')
    const {userID,setUserID,newVideo,setNewVideo }= useContext(UserContext)
    const {firstName,setFirstName,lastName,setLastName}= useContext(UserContext)
    const {sportID,setSportID,langID,setLangID}= useContext(UserContext)
    const [topPosition, setTopPosition] = useState(null);
    const [isLoading,setIsLoading]=useState(false)
    const [photoForFullScreen,setPhotoForFullScreen]=useState('')
    const [reportDetail,setReportDetail]=useState('')
    const [reportSent,setReportSent]=useState(false)
    const [success,setSuccess]=useState(false)
    const [show, setShow] = useState(false);
    const [showVideo,setShowVideo]=useState(true)
    const [totalRecations,setTotalReactions]=useState(0)
    const [reactions,setReactions]=useState([])
    const navigate=useNavigate()
    const vidEl=useRef()
    const elementRef=useRef(null)
    const mainDivRef=useRef(null)

    useEffect(()=>{
        //console.log(props.item)
        setReactions(props.item.reactions)
        setArticlePhoto(props.articlePhoto)

        calculateTotalReactions()

        if (props.item.VideoID?.length>0){
            if (newVideo.split('.')[0]==props.item.VideoID){
              setShowVideo(false)
              var checkStatus=setInterval(function(){
                const url=process.env.REACT_APP_VIDEOSERVER_API+'upload/'+props.item.VideoID
                axios.get(url)
                .then(res=>{
                   // console.log(res.data)
                    if (res.data[0].status==1){
                        setShowVideo(true)
                        setNewVideo('')
                        clearInterval(checkStatus)
                    }
                })
            }, 3000);
            }
          }

    },[])
    

    const calculateTotalReactions=()=>{
        var tr=0
        if (props.item.reactions.filter(xitem=>xitem.RankType==3).length>0){
            props.item.reactions.filter(xitem=>xitem.RankType==3).forEach(xitem=>{
                tr=tr+parseInt(xitem.v)
            })
            
        }
        if (props.item.reactions.filter(xitem=>xitem.RankType==2).length>0){
            props.item.reactions.filter(xitem=>xitem.RankType==2).forEach(xitem=>{
                tr=tr+parseInt(xitem.v)
            })
        }
        if (props.item.reactions.filter(xitem=>xitem.RankType==1).length>0){
            props.item.reactions.filter(xitem=>xitem.RankType==1).forEach(xitem=>{
                tr=tr+parseInt(xitem.v)
            })
        }
        setTotalReactions(tr)
    }

    const calculateTotalReactionsState=(reactionsArr)=>{
        //console.log(reactionsArr)
        var tr=0
        if (reactionsArr.filter(xitem=>xitem.RankType==3).length>0){
            reactionsArr.filter(xitem=>xitem.RankType==3).forEach(xitem=>{
                tr=tr+parseInt(xitem.v)
            })
            
        }
        if (reactionsArr.filter(xitem=>xitem.RankType==2).length>0){
            reactionsArr.filter(xitem=>xitem.RankType==2).forEach(xitem=>{
                tr=tr+parseInt(xitem.v)
            })
        }
        if (reactionsArr.filter(xitem=>xitem.RankType==1).length>0){
            reactionsArr.filter(xitem=>xitem.RankType==1).forEach(xitem=>{
                tr=tr+parseInt(xitem.v)
            })
        }
        setTotalReactions(tr)
    }

    function toggleActive(r,rr) {
            
            document.getElementById("challenge-button_"+props.index+"_1")?.classList.remove('active')
            document.getElementById("challenge-button_"+props.index+"_2")?.classList.remove('active')
            document.getElementById("challenge-button_"+props.index+"_3")?.classList.remove('active')
    
            var btn
            if (r==3){
                btn=document.getElementById("challenge-button_"+props.index+"_3")
            }
            if (r==2){
                btn=document.getElementById("challenge-button_"+props.index+"_2")
            }
            if (r==1){
                btn=document.getElementById("challenge-button_"+props.index+"_1")
            }       

            document.getElementById("f-animation"+props.index).src=`${foutline}`
            document.getElementById("thunder-animation"+props.index).src=`${thunder7w}`
            document.getElementById("achievement-animation"+props.index).src=`${medal3w}`

            const img = btn.querySelector('img')


            img.classList.add('zoom-anim');
            setTimeout(() => {
                img.classList.remove('zoom-anim');
            }, 1000);

            var xtotReactions=totalRecations
            const hadAReaction=reactions.filter(xitem=>xitem.ReactUserUUID==userID).length==1
            var xreactions=reactions//.filter(xitem=>xitem.ReactUserUUID!=userID)
            

            if (hadAReaction){
                //console.log('Has a reaction')
                if (reactions.filter(xitem=>xitem.ReactUserUUID==userID && xitem.RankType==r).length==1){
                   // console.log('Remove reaction')
                    xreactions=[...xreactions.filter(xitem=>xitem.ReactUserUUID!=userID)]
                    setReactions(xreactions)
                }else{
                    //console.log('Edit reaction')
                    xreactions.forEach(xr=>{
                        if (xr.ReactUserUUID==userID){
                            xr.RankType=r
                        }
                    })
                    setReactions([...xreactions])                    
                }
                

                
            }else{   
               // console.log('New reaction')         
                const obj={
                    ChUserID:0,
                    ChUserUUID:'',
                    ReactUserUUID:userID,
                    ReactUserID:0,
                    err:0,
                    ChallengeID:props.item.ChallengeID,
                    v:1,
                    RankType:r
                }

                console.log(obj)
                xreactions.push(obj)
                setReactions([...xreactions])
            }
           
            calculateTotalReactionsState(xreactions)
            sendReaction(r)

    }

    const sendReaction=(r)=>{
        const obj={
            ChallengeID:props.item.UUID,
            ChUserID:props.item.UsrUUID,
            ReactUserID:userID,
            Rank:r,
            LangID:langID,
            ReactFullName:firstName+" "+lastName
        }
        var url=''
        if (props.item.Type==3){
            url=process.env.REACT_APP_API_SERVER+'challengereactions'
        }
        if (props.item.Type==2){
            url=process.env.REACT_APP_API_SERVER+'postreactions'
        }

        axios.post(url,obj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("token")
                }            
            })
          .then(res=>{
      

           // console.log(res.data)
            
          }).catch(err=>{
            console.log(err)
      
          })
        
    }

    const showModal=()=>{
        setShow(true)
        setReportSent(false)
        setReportDetail('')
        setSuccess(false)
        if (mainDivRef.current) {
            mainDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            window.scrollBy({
                top: 400, // Scroll down 100 pixels
                behavior: 'smooth' // Smooth scrolling
            });
        }     

    }

    const sendReport=(e)=>{
        e.preventDefault()
        
        const obj={
            reportDetail,
            contentType:props.item.Type,
            UUID:props.item.UUID,
            UsrUUID:props.item.UsrUUID,
            userID
        }

        axios.post(process.env.REACT_APP_API_SERVER+'report',obj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("token")
                }            
            })
          .then(res=>{

            
            setReportSent(true)
            setSuccess(true)
            cancelReport()
            //console.log(res.data)
      
            
          }).catch(err=>{
            console.log(err)
      
          })
    }

    const cancelReport=()=>{
        setShow(false)

        if (mainDivRef.current) {
            mainDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

        }         
    }
  return (
    <>
        {articlePhoto && authorImg && (!isLoading) && 
            <div ref={mainDivRef} className="home2-posts-item" >
            <div className="home-avatar-item bg-white">
                <div onClick={()=>props.item.UsrUUID.length>0 ?  navigate('/profile/'+props.item.UsrUUID) : null}  className="home2-user-photo" style={{backgroundImage:'url('+props.authorImg+')'}}></div>
                <div className="home-header">
                    <div className="home-header-text">{props.author}</div>
                    <div className="home-header-time d-flex">{moment(props.item.DateCreated).format('DD/MM/YYYY')} 
                        <i className="bi bi-globe-americas ps-1" style={{color:'black'}}></i>
                        {/* <img className="ps-1" src={cube3} alt="cube icon" style={{height:'14px'}}/> */}
                        {props.item.Type==3 &&
                        <img className="ps-1" src={cube2} alt="cube icon orange" style={{height:'14px'}}/>
                        }
                    </div>
                </div>
                {(props.item.Type==2 || props.item.Type==3) && userID!=props.item.UsrUUID &&
                <div className="octagon ms-auto">
                    <button className="octagon-button" onClick={()=>{showModal(props.index)}}>
                        <img src={octagon} alt="information icon" style={{width:'20px'}}/>
                    </button>
                </div>
                }
            </div>
            <div className="home2-user-line"></div>
            <div className="home-practice-days bg-white">

                    {(props.item.Title.length>0 || props.item.Descr.length>0) &&
                        <>
                        
                        <b>{props.item.Title}</b><br/>
                        {props.item.Descr.length>0 &&
                        <div
                        dangerouslySetInnerHTML={{ __html: props.item.Descr.replace(/\n/g, '<br>') }}

                        />
                        }

                        <br/>
                        </>
                    }


            </div>
            <div className="home2-post-image">
                {props.item.Photo?.length>0 &&

                 <img  src={props.articlePhoto} alt="Post Image" className="dynamic-image"/>

                }
                {props.item.VideoID?.length>0 &&  !(props.item.Type==1 && props.item.IsChallenge==1 ) &&
                <>
                {showVideo &&
                <video preload="false" playsInline type="video/mp4" id="videoPlayer" controls width="100%" height="100%" poster={process.env.REACT_APP_VIDEOSERVER_API+''+props.item.VideoID+'.jpg'}>
                    <source src={`${process.env.REACT_APP_VIDEO_BASE_URL}${props.item.VideoID}.smil/playlist.m3u8#t0.01`} type="video/mp4"/>
                </video>
                }
                {!showVideo &&
                    <div className="home-post-video-wrapper" style={{borderWidth:'1px',borderColor:'red',backgroundColor:'#f0f0f0'}}>
                    Processing your video, please wait...
                    </div>
                }
                </>
                }
            </div>
            {(props.item.Type==3 || props.item.Type==2) &&
            <div className="d-flex justify-content-between px-3 bg-white">
                <div className="home-post-points-picker">
                    <div>
                        <button onClick={()=>toggleActive(3)} className="challenge-press-button bg-transparent" id={`challenge-button_${props.index}_3`}>
                        <img src={reactions.filter(xitem=>xitem.RankType==3 && xitem.ReactUserUUID==userID && xitem.ChallengeID==props.item.ChallengeID).length>0 ? foutline2 : foutline} id={`f-animation${props.index}`}  className=""/>
                        </button>
                    </div>
                    <div>
                        <button onClick={()=>toggleActive(2)}  className="challenge-press-button bg-transparent" id={`challenge-button_${props.index}_2`}>
                            <img src={reactions.filter(xitem=>xitem.RankType==2 && xitem.ReactUserUUID==userID && xitem.ChallengeID==props.item.ChallengeID).length>0 ? thunder5 : thunder7w} id={`thunder-animation${props.index}`} />
                        </button>
                    </div>
                    <div>
                        <button onClick={()=>toggleActive(1)}  className="challenge-press-button bg-transparent" id={`challenge-button_${props.index}_1`}>
                            <img src={reactions.filter(xitem=>xitem.RankType==1 && xitem.ReactUserUUID==userID && xitem.ChallengeID==props.item.ChallengeID).length>0 ? achievement: medal3w} id={`achievement-animation${props.index}`} />
                        </button>
                    </div>
                </div>
                <div className="home2-post-points">
                    {reactions.filter(xitem=>xitem.RankType==3).length>0 &&
                    <div><img src={flame} alt="flame icon"/></div>
                    }
                    {reactions.filter(xitem=>xitem.RankType==2).length>0 &&
                    <div><img src={thunder5} alt="thunder icon"/></div>
                    }
                    {reactions.filter(xitem=>xitem.RankType==1).length>0 &&
                    <div><img src={achievement} alt="achievement icon"/></div>
                    }
                    {totalRecations}
                </div>
            </div>
            }

        <div  id={`report${props.index}`} style={{display: show ? 'block' : 'none',marginLeft:20,marginRight:20}}>
          <hr/>
          <h5>Objectionable Content Report</h5>
              <form onSubmit={sendReport}>
                <div className="form-floating mb-3">
                        <input
                        disabled={reportSent}
                        maxLength={250}
                        type="text"
                        className="form-control"
                        id="floatingInput2"
                        placeholder="Mobile phone"
                        required
                        value={reportDetail}
                        onChange={(e)=>setReportDetail(e.target.value)}
                        />
                        {!reportSent &&
                        <label for="floatingInput2">Please Explain</label>
                        }       

                        {reportSent &&
                        <label for="floatingInput2"><b>Report sent to administrator</b></label>
                        }       



                    </div>  


              <div className="row w-100 m-0">
                <div className="col p-0"><button type="button" className="w-100 fw-bold" onClick={()=>cancelReport()}>
                    {!reportSent ? 'Cancel' : 'Close'}
                </button></div>
                {!reportSent &&
                <div className="col p-0"><button type="submit" className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>Send Report</button></div>
                }
                {/* <div className="col p-0"><button type="button" data-bs-dismiss="modal" onClick={()=>navigate('/challengepost/'+challenge.UUID+'/'+challenge.LangID)} className="w-100 fw-bold" style={{color: '#f95d2d', borderLeft: '1px solid #f95d2d'}}>OK</button></div> */}
              </div>  
              </form>
              <hr/>
              <br/><br/>
        </div>

        </div>
        }
        </>
    )

}
